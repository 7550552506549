import { ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { currencyFormat, numberTwoDecimalsFormat, percentFormat, twoDecimalsPercentFormat } from '../../Format';
import { Component } from '../../models/Component';
import { useApi } from '../../contexts/ApiContext';
import { ReplacementCostType, useAssetByComponentId } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';
import ReplacementCostList from '../ReplacementCost/ReplacementCostList';
import Loader from '../../icons/Loader.svg?react';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';

const ComponentView: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const { refetch, data: asset = {} as Asset } = useAssetByComponentId(parseInt(id));
  const { roles } = useAuth();
  const { checkAssetClassEditable } = useClient();
  const [component, setComponent] = useState<Component>({} as Component);

  useEffect(() => {
    if (Object.keys(asset).length > 0) {
      const component = asset.components.find((component) => component.id === parseInt(id));
      setComponent(component || ({} as Component));
    }
  }, [asset]);
  const {
    componentId,
    name,
    type,
    subType,
    valuationType,
    consumptionScore,
    rspPctLong,
    rspPctShort,
    ulShort,
    ulLong,
    rulShort,
    rulLong,
    rvShort,
    rvLong,
    rv,
    valuationProfile,
    obsolescenceProfile,
    depreciationPolicy,
    acquisitionDate,
    decommissionDate,
    grossShort,
    grossLong,
    gross,
    currentValueShort,
    currentValueLong,
    currentValue,
    depreciationExpenseShort,
    depreciationExpenseLong,
    depreciationExpense,
    weightedAverageUL,
    weightedAverageRUL,
    longLifePct,
    shortLifePct,
    errors,
    warnings,
    replacementCosts,
    componentAssumptions,
    scoreChangeDate,
  } = component;

  if (Object.keys(component).length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-sm font-medium text-gray-500">Loading Components</p>
      </div>
    );
  }

  const aDate = acquisitionDate ?? asset.acquisitionDate;
  const dDate = decommissionDate ?? asset.decommissionDate;
  const acquisition = aDate ? format(new Date(aDate), 'MMM dd, yyyy') : '?';
  const decommission = dDate ? format(new Date(dDate), 'MMM dd, yyyy') : '?';
  const isBothDatesEmpty = acquisition === '?' && decommission === '?';
  return (
    <div className="p-4 sm:px-6 lg:px-8 space-y-4">
      <div className=" space-y-6">
        <div className="bg-white overflow-hidden shadow rounded-md">
          <h2 className="sr-only" id="profile-overview-title">
            Asset Overview
          </h2>
          <div className="bg-white p-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <NavLink className="text-indigo-600 hover:text-indigo-900 text-sm font-medium" to={`/assets/${asset.id}`}>
                    {asset.reference}
                  </NavLink>
                  <p className="text-xl font-bold text-gray-900 sm:text-2xl">{name}</p>
                  <p className="text-base italic text-gray-500 sm:text-base">{componentId}</p>
                  <p className="text-sm font-medium text-gray-600">
                    {type}{' '}
                    <span className="ml-4">
                      {subType && (
                        <NavLink
                          className="text-indigo-600 hover:text-indigo-900 text-sm font-medium"
                          to={`/componentassumptions/${componentAssumptions?.id}`}
                        >
                          {subType}
                        </NavLink>
                      )}
                    </span>
                  </p>
                  {!isBothDatesEmpty && (
                    <p className="text-sm font-medium text-gray-600">
                      {acquisition} → {decommission}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-5 flex justify-between sm:mt-0">
                {!roles.includes(Roles.Viewer) && checkAssetClassEditable(asset.assetClass) && (
                  <Link to={`/components/${id}/edit`} className="ml-1 btn btn-primary">
                    Edit
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {Object.keys(errors).length > 0 && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                There is {Object.keys(errors).length} errors with this component
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <ul className="list-disc pl-5 space-y-1">
                  {Object.keys(errors).map((error) => (
                    <li>
                      {error} - {errors[error]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {Object.keys(warnings).length > 0 && (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                There is {Object.keys(warnings).length} warnings with this component
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <ul className="list-disc pl-5 space-y-1">
                  {Object.keys(warnings).map((warning) => (
                    <li>
                      {warning} - {warnings[warning]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="">
        {/* <h3 className="text-lg leading-6 font-medium text-gray-900">Last 30 days</h3> */}
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {/* {stats.map((item) => ( */}
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate flex flex-row justify-between">
              <div>Consumption Score</div>{' '}
              {scoreChangeDate && (
                <span className="ml-2 text-sm font-medium text-gray-500">
                  Updated {`${format(new Date(scoreChangeDate), 'd MMM yyyy')}`}
                </span>
              )}
            </dt>
            <dd className="mt-1 text-2xl font-semibold text-indigo-900">{consumptionScore}</dd>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Depreciation Policy</dt>
            <dd className="mt-1 text-2xl font-semibold text-indigo-900">{depreciationPolicy}</dd>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Valuation Policy</dt>
            <dd className="mt-1 text-2xl font-semibold text-indigo-900">{valuationType}</dd>
          </div>
        </dl>
      </div>
      <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Gross
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Current Value
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Remaining Service Potential
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Depreciation Expense
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Useful Life
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Remaining Useful Life
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
              >
                Residual Value
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Profile
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Odd row */}
            <tr className="bg-white">
              <td className="flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 justify-between">
                Short Life
                <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                  {percentFormat.format(shortLifePct)}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {currencyFormat.format(grossShort)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {currencyFormat.format(currentValueShort)}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {rspPctShort && twoDecimalsPercentFormat.format(rspPctShort)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {currencyFormat.format(depreciationExpenseShort)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {ulShort && numberTwoDecimalsFormat.format(ulShort)} yrs
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {rulShort && numberTwoDecimalsFormat.format(rulShort)} yrs
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {currencyFormat.format(rvShort)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{valuationProfile}</td>
            </tr>

              <tr className="bg-white">
                <td className="flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 justify-between">
                  Long Life
                  <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                    {longLifePct && percentFormat.format(longLifePct)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {longLifePct <= 0 ? `-` : currencyFormat.format(grossLong)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {longLifePct <= 0 ? `-` : currencyFormat.format(currentValueLong)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {longLifePct <= 0 ? `-` : rspPctLong && twoDecimalsPercentFormat.format(rspPctLong)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {longLifePct <= 0 ? `-` : currencyFormat.format(depreciationExpenseLong)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {longLifePct <= 0 ? `-` : ulLong && numberTwoDecimalsFormat.format(ulLong)} yrs
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {longLifePct <= 0 ? `-` : rulLong && numberTwoDecimalsFormat.format(rulLong)} yrs
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {longLifePct <= 0 ? `-` : currencyFormat.format(rvLong)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {longLifePct <= 0 ? (
                    `-`
                  ) : obsolescenceProfile === null ? (
                    <span className="text-red-500">N/A</span>
                  ) : (
                    obsolescenceProfile
                  )}
                </td>
              </tr>

            <tr className="bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900" />
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {currencyFormat.format(gross)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {currencyFormat.format(currentValue)}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500" />
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {currencyFormat.format(depreciationExpense)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {numberTwoDecimalsFormat.format(weightedAverageUL)} yrs
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                {numberTwoDecimalsFormat.format(weightedAverageRUL)} yrs
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">{currencyFormat.format(rv)}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500" />
            </tr>

            {/* More items... */}
          </tbody>
        </table>
      </div>
      {(replacementCosts.length > 0 || component.valuationType === 'None' || component.valuationType === 'Direct Cost') && (
        <>
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Replacement Costs</h1>
          <ReplacementCostList
            parentId={parseInt(id)}
            refetch={refetch}
            replacementCostList={replacementCosts}
            type={ReplacementCostType.Component}
            editable={checkAssetClassEditable(asset.assetClass)}
          />
        </>
      )}
    </div>
  );
};

export default ComponentView;
