import { FC } from 'react';
import Loader from '../icons/Loader.svg?react';

interface Props {
  message: string;
}

const Loading: FC<Props> = ({ message = '' }) => (
  <div className="flex flex-col justify-center items-center h-screen">
    <Loader className="animate-spin w-6 h-6 mb-3" />
    <p className="text-base font-medium text-gray-500">Loading {message}</p>
  </div>
);

export default Loading;
