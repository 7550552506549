import { ErrorBoundary } from 'react-error-boundary';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import { createBrowserHistory } from 'history';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { OidcProvider, useOidc } from '@axa-fr/react-oidc';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Home } from './pages/Home';
import { AuthProvider } from './contexts/AuthContext';
import configuration from './configuration';
import { ApiProvider } from './contexts/ApiContext';
import { CustomCallback } from './components/CustomCallback';
import Loader from './icons/Loader.svg?react';
import { ClientProvider } from './contexts/ClientContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 180000,
    },
  },
});

const AuthenticatingCallback: React.FC<{}> = () => (
  // TODO: Make this page Prettier
  <div className="flex flex-col justify-center items-center h-screen">
    <Loader className="animate-spin w-6 h-6 mb-3" />
    <p className="text-base font-medium text-gray-500">Authenticating...</p>
  </div>
);
const CallbackSuccessComponent: React.FC<{}> = () => (
  // TODO: Make this page Prettier
  <div className="flex flex-col justify-center items-center h-screen">
    <Loader className="animate-spin w-6 h-6 mb-3" />
    <p className="text-base font-medium text-gray-500">Redirecting...</p>
  </div>
);
const AuthErrorComponent: React.FC<{}> = () => {
  const { logout } = useOidc();

  useEffect(() => {
    const timer = setTimeout(() => {
      logout();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    // TODO: Make this page Prettier
    <div className="flex flex-col min-h-screen justify-center items-center text-base bg-gray-200 text-gray-700 sm:text-xl">
      <ExclamationTriangleIcon className="w-8 h-8 text-red-500" />
      <h1>Authentication Error...redirecting back to login</h1>
    </div>
  );
};

const AppErrorComponent: React.FC<{}> = () => (
  <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div className="text-center">
      <p className="text-base font-semibold text-indigo-600">500</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Something went wrong</h1>
      <p className="mt-6 text-base leading-7 text-gray-600">We are working on fixing the problem.</p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a href={import.meta.env.VITE_REPORTING_API} className="btn btn-primary">
          Go back home
        </a>
        <a href="mailto:support@assetvaluer.net?subject=AVP Error" className="text-sm font-semibold text-gray-900">
          Contact support <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  </main>
);

const logError = (error: Error, info: { componentStack: string }): void => {
  Sentry.captureException(error);
};

const App: React.FC = () => {
  Sentry.init({
    dsn: 'https://5258a6b315e07061d40d7e581b01dd35@o4504081246912512.ingest.sentry.io/4505627508211712',
    ignoreErrors: [/status code 401/],
    enabled: process.env.NODE_ENV !== 'development',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  const router = createBrowserRouter([
    {
      path: '*',
      element: (
        <ErrorBoundary fallback={<AppErrorComponent />} onError={logError}>
          <Home />
        </ErrorBoundary>
      ),
    },
  ]);

  return (
    <OidcProvider
      loadingComponent={CustomCallback}
      authenticatingComponent={AuthenticatingCallback}
      authenticatingErrorComponent={AuthErrorComponent}
      configuration={configuration}
      callbackSuccessComponent={CallbackSuccessComponent}
    >
      <ApiProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ClientProvider>
              <RouterProvider router={router} />
            </ClientProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ApiProvider>
    </OidcProvider>
  );
};

export default App;
