import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { formatRelative } from 'date-fns';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import PapaParse from 'papaparse';
import { Listbox, Transition } from '@headlessui/react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useStrategy } from '../contexts/StrategyContext';
import { ToastType, useToast } from '../contexts/ToastContext';
import DocumentReport from '../icons/DocumentReport.svg?react';
import DocumentText from '../icons/DocumentText.svg?react';
import TickCircle from '../icons/TickCircle.svg?react';
import Exclaimation from '../icons/Exclaimation.svg?react';
import { downloadHandler } from '../Helper';
import { useApi } from '../contexts/ApiContext';
import { HierarchyType } from '../models/HierarchyType';

interface CSVFile {
  file: FileWithPath;
  isValid: boolean | null;
  strategyId: number;
  closeAction(): void;
}

export const Strategy: React.FC<{}> = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id: strategyId = '' } = useParams<{ id: string }>();
  const { getStrategy } = useStrategy();
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [file, setFile] = useState<FileWithPath>({} as FileWithPath);
  const { id, name, numberOfComponents, assetClass, assetClassId, baselineStrategyId, baselineStrategyName, lastRefreshed } =
    getStrategy(parseInt(strategyId));
  const defaultStrategy: HierarchyType = {
    label: baselineStrategyName,
    value: baselineStrategyId,
    isValid: true,
  };

  const [selectedStrategy, setSelectedStrategy] = useState<HierarchyType>(defaultStrategy);
  const [strategies, setStrategies] = useState<HierarchyType[]>([]);

  const onDrop = useCallback((acceptedFiles) => {
    PapaParse.parse(acceptedFiles[0], {
      complete(results, file) {
        if (results.errors.length) {
          setIsValid(false);
        } else {
          setIsValid(true);
        }
      },
    });
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
  });

  const onStrategyChange = (event) => {
    console.log('event', event);
    setSelectedStrategy(event);
  };

  const dateRefreshed = lastRefreshed ? formatRelative(new Date(lastRefreshed), new Date()) : '';

  const downloadStrategy = (strategyId: number): void => {
    api.post('/api/Strategy/Export', { strategyId }, { responseType: 'blob' }).then(
      ({ data }) => {
        downloadHandler(data, `${name}.csv`);
      },
      (error) => {
        addToast('API Fetch Failed', ToastType.Error);
      }
    );
  };

  const downloadCashFlowProjections = (strategyId: number): void => {
    api.post('/api/Reports/CashFlowProjections', { strategyId }, { responseType: 'blob' }).then(
      ({ data }) => {
        downloadHandler(data, `Detailed ${name}.csv`);
      },
      (error) => {
        addToast('Export Failed', ToastType.Error);
      }
    );
  };

  const downloadRenewalsSchedule = (strategyId: number): void => {
    api.post('/api/Reports/RenewalsSchedule', { strategyId }, { responseType: 'blob' }).then(
      ({ data }) => {
        downloadHandler(data, `Renewals Schedule for ${name}.csv`);
      },
      (error) => {
        addToast('Export Failed', ToastType.Error);
      }
    );
  };

  useEffect(() => {
    if (assetClassId != null) {
      api
        .post('/api/Strategy/GetStrategiesByAssetClass', {
          assetClassId,
          strategy: name,
        })
        .then(
          ({ data }) => {
            setStrategies(data);
          },
          (error) => {
            addToast('Export Failed', ToastType.Error);
          }
        );
    }
  }, [assetClassId]);

  return (
    <div className="flex-1 relative pb-8 z-0 overflow-y-auto p-4 space-y-4">
      <div className="bg-white rounded-lg shadow">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
            <div className="flex-1 min-w-0">
              <div className="flex items-center">
                <div>
                  <div className="flex items-center">
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">{name}</h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Company</dt>
                    <dd className="flex items-center text-sm leading-5 text-cool-gray-500 font-medium capitalize sm:mr-6">
                      <svg
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {numberOfComponents}
                    </dd>
                    <dt className="sr-only">Account status</dt>
                    <dd className="mt-3 flex items-center text-sm leading-5 text-cool-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                      <ArrowPathIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" />
                      {dateRefreshed}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <span className="shadow-sm">
                <button onClick={(): void => downloadStrategy(id)} type="button" className="btn btn-secondary">
                  Export Assumptions
                </button>
              </span>
              {Object.entries(file).length !== 0 ? (
                <FileInput
                  file={file}
                  isValid={isValid}
                  closeAction={(): void => setFile({} as FileWithPath)}
                  strategyId={parseInt(strategyId)}
                />
              ) : (
                <div {...getRootProps()} className="btn btn-secondary">
                  <div className="text-center">
                    <input {...getInputProps()} />
                    <p className="flex">
                      <button
                        type="button"
                        className="font-medium focus:outline-none focus:underline transition duration-150 ease-in-out"
                      >
                        Import Assumptions
                      </button>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="max-w-6xl">
          {/* Reports */}
          <div className="">
            <select
              onChange={(e): void =>
                setSelectedStrategy(strategies.find((strat) => strat.value === parseInt(e.target.value)) ?? selectedStrategy)
              }
              className="my-1 block pl-3 pr-10 py-2 text-base border-none rounded-md bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {strategies.map((strategy) => (
                <option value={strategy.value}>{strategy.label}</option>
              ))}
            </select>
          </div>

          <h2 className="text-lg leading-6 font-medium text-cool-gray-900">Reports</h2>
          <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <div className="col-span-1 bg-white rounded-md shadow">
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">Analysis of Results</h3>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200">
                <div className="-mt-px flex">
                  <div className="-ml-px w-0 flex-1 flex">
                    <Link
                      to={`/reports/detailanalysis/${id}/${selectedStrategy.value}`}
                      className="w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent hover:text-gray-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition ease-in-out duration-150"
                    >
                      <DocumentReport className="w-5 h-5 text-gray-400" />
                      <span className="ml-3">Run Report</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1 bg-white rounded-md shadow">
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">Detailed Strategy</h3>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200">
                <div className="-mt-px flex">
                  <div className="-ml-px w-0 flex-1 flex">
                    <button
                      onClick={(): void => downloadCashFlowProjections(id)}
                      type="button"
                      className="w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent hover:text-gray-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition ease-in-out duration-150"
                    >
                      <DocumentText className="w-5 h-5 text-gray-400" />
                      <span className="ml-3">Export</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1 bg-white rounded-md shadow">
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">Renewals Schedule</h3>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200">
                <div className="-mt-px flex">
                  <div className="-ml-px w-0 flex-1 flex">
                    <button
                      onClick={(): void => downloadRenewalsSchedule(id)}
                      type="button"
                      className="w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent hover:text-gray-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition ease-in-out duration-150"
                    >
                      <DocumentText className="w-5 h-5 text-gray-400" />
                      <span className="ml-3">Export</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FileInput: React.FC<CSVFile> = ({ file, isValid, closeAction, strategyId }) => {
  const api = useApi();
  const [isExporting, setIsExporting] = useState(false);
  const { addToast } = useToast();
  const uploadCSV = (strategyId, file): void => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('strategyId', strategyId);
    setIsExporting(true);
    api.post('/api/Strategy/Import', formData).then(
      ({ data }) => {
        setIsExporting(false);
        addToast(data.message);
        closeAction();
      },
      (error) => {
        setIsExporting(false);
        addToast('API Fetch Failed', ToastType.Error);
        closeAction();
      }
    );
  };
  if (isValid) {
    return (
      <button
        onClick={(): void => uploadCSV(strategyId, file)}
        type="button"
        className="btn border-transparent leading-5 text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition duration-150 ease-in-out"
      >
        Upload {file.name}
        <span>
          <TickCircle className="animate-pulse ml-2 h-5 w-5 text-white" />
        </span>
      </button>
    );
  }
  return (
    <button
      onClick={closeAction}
      type="button"
      className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium text-red-700 bg-red-100 hover:bg-red-50 focus:outline-none focus:border-red-300 focus:shadow-outline-red active:bg-red-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
    >
      Error with {file.name}
      <span>
        <Exclaimation className="animate-bounce ml-2 h-5 w-5 text-red-700" />
      </span>
    </button>
  );
};
