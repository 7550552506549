import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { XMarkIcon } from '@heroicons/react/24/outline';
import Archive from '../../icons/Archive.svg?react';
import Briefcase from '../../icons/BriefcaseSolid.svg?react';
import Loader from '../../icons/Loader.svg?react';
import JobModal from '../../components/JobModal';
import { Job } from '../../models/Job';
import { useApi } from '../../contexts/ApiContext';
import { DeleteJobModal } from '../../components/DeleteJobModal';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { Roles } from '../../models/Role';
import { useAuth } from '../../contexts/AuthContext';

type Variables = { jobId: number };

interface Props {
  jobs: Job[];
}

interface OpenJobProps {
  jobs: Job[];
  openDeleteModal(): void;
  setJobToDelete: (jobToDelete: number) => void;
  setNameOfJobToDelete: (nameOfJobToDelete: string) => void;
  roles: string[];
}

const OpenJobs: React.FC<OpenJobProps> = ({ jobs, openDeleteModal, setJobToDelete, setNameOfJobToDelete, roles }) => (
  <div className="md:items-center md:justify-between pt-4 sm:px-6 lg:px-8">
    <h2 className="text-lg leading-6 font-medium text-gray-900">Current Jobs</h2>
    <div className="bg-white shadow rounded-md overflow-hidden mt-2" />
    <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 4xl:grid-cols-3">
      {jobs.map((job) => {
        const [first, second, third, fourth, fifth, sixth, ...others] = job.assetClasses;
        const firstSix = [first, second, third, fourth, fifth, sixth];
        return (
          <div key={job.id}>
            <div className="bg-white overflow-hidden shadow rounded-md col-span-1 flex flex-col h-full justify-between">
              <div className="flex justify-end">
                {!roles.includes(Roles.Viewer) && (
                  <button
                    type="button"
                    className="px-1 py-1"
                    onClick={(): void => {
                      openDeleteModal();
                      setJobToDelete(job.id !== undefined ? job.id : 0);
                      setNameOfJobToDelete(job.name);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-7 w-7 text-gray-400 hover:text-red-700" aria-hidden="true" />
                  </button>
                )}
              </div>
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-center">
                  <div className="w-0 flex-1">
                    <div className="space-x-4">
                      <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                        {job.type}
                      </span>
                      {job.status === 'Draft' && (
                        <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-700/10">
                          {job.status}
                        </span>
                      )}
                    </div>
                    <dd className="flex items-baseline pt-2">
                      <div className="text-2xl font-semibold text-gray-900">{job.name}</div>
                    </dd>
                    <dt className="text-sm font-medium text-gray-500 truncate">{job.externalId}</dt>
                  </div>
                </div>
              </div>
              <div className="sm:px-6 sm:pb-4">
                <div className="text-sm">
                  {/* {firstSix.map((ac) => {
                    if (ac !== undefined) {
                      return (
                        <span
                          key={ac.value}
                          className="inline-flex items-center px-2 py-0.5 text-xs rounded-md rounded-md font-medium bg-indigo-100 text-indigo-800 m-2"
                        >
                          {ac.label}
                        </span>
                      );
                    }
                    return null;
                  })}
                  {others && others.length > 0 && (
                    <span className="flex-shrink-0 text-xs font-medium leading-5">+{others.length}</span>
                  )} */}
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <Link to={`/jobs/${job.id}`} key={job.id}>
                    <button className="btn btn-link">View</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

const ArchivedJobs: React.FC<Props> = ({ jobs }) => (
  <div className="md:items-center md:justify-between pt-6 sm:px-6 lg:px-8">
    <h2 className="text-lg leading-6 font-medium text-gray-900">Archived Jobs</h2>
    {/* This example requires Tailwind CSS v2.0+ */}
    <div className="bg-white shadow rounded-md overflow-hidden mt-2">
      <ul className="divide-y divide-gray-200">
        {jobs
          .sort((a, b) => (a.effectiveDateOfValuation < b.effectiveDateOfValuation ? 1 : -1))
          .map((job) => (
            <li key={job.id}>
              <Link to={`/jobs/${job.id}`} className="block hover:bg-gray-50">
                <div className="px-4 py-4 flex items-center sm:px-6">
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                      <div className="flex text-sm font-medium text-gray-700 truncate space-x-3 items-center">
                        <p>{job.name}</p>
                        {job.type === 'Desktop' ? (
                          <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                            {job.type}
                          </span>
                        ) : (
                          <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                            {job.type}
                          </span>
                        )}
                      </div>
                      <div className="mt-2 flex">
                        <div className="flex items-center text-sm text-gray-500">
                          <Archive className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                          <p>Valuation: {format(new Date(job.effectiveDateOfValuation), 'PP')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 flex-shrink-0">
                    {/* Heroicon name: chevron-right */}
                    <svg
                      className="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  </div>
);

const FinalisedJobs: React.FC<Props> = ({ jobs }) => (
  <div className="md:items-center md:justify-between pt-6 sm:px-6 lg:px-8">
    <h2 className="text-lg leading-6 font-medium text-gray-900">Finalised Jobs</h2>
    <div className="bg-white shadow rounded-md overflow-hidden mt-2">
      <ul className="divide-y divide-gray-200">
        {jobs.map((job) => (
          <li key={job.id}>
            <Link to={`/jobs/${job.id}`} className="block hover:bg-gray-50">
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <div className="flex text-sm font-medium text-gray-700 truncate items-center space-x-3">
                      <p>{job.name}</p>
                      {job.type === 'Desktop' ? (
                        <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                          {job.type}
                        </span>
                      ) : (
                        <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                          {job.type}
                        </span>
                      )}
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex items-center text-sm text-gray-500">
                        <Briefcase className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                        <p>Valuation: {format(new Date(job.effectiveDateOfValuation), 'PP')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0">
                    <div className="flex overflow-hidden">
                      {/* {firstThree.map((ac) => {
                          if (ac !== undefined) {
                            return (
                              <span
                                key={ac.value}
                                className="inline-flex items-center px-2 py-0.5 text-xs rounded-md font-medium bg-indigo-100 text-indigo-800 mr-2"
                              >
                                {ac.label}
                              </span>
                            );
                          }
                          return null;
                        })}

                        {others && others.length > 0 && (
                          <span className="flex-shrink-0 text-xs font-medium leading-5">+{others.length}</span>
                        )} */}
                    </div>
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                  {/* Heroicon name: chevron-right */}
                  <svg
                    className="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const JobList: React.FC<{}> = () => {
  const api = useApi();
  const [isDeletingJob, setIsDeletingJob] = useState<boolean>(false);
  const { addToast } = useToast();
  const { isLoading, data = [] as Job[] } = useQuery<Job[], Error>(['jobslist'], () =>
    api.get('/api/Job/List').then((res) => res.data)
  );
  const { roles } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteJobModal, setDeleteJobModal] = useState<boolean>(false);
  const [jobToDelete, setJobToDelete] = useState<number>(0);
  const [nameOfJobToDelete, setNameOfJobToDelete] = useState<string>('');
  const queryClient = useQueryClient();
  const { mutate } = useMutation((params: Variables) => api.post('/api/Job/Delete', params));
  const deleteJob = (): void => {
    setIsDeletingJob(true);
    mutate(
      { jobId: jobToDelete },
      {
        onSuccess: ({ data }) => {
          addToast(`${nameOfJobToDelete} has been deleted`);
        },
        onError: (error) => {
          addToast(`Unable to remove Job ${nameOfJobToDelete}`, ToastType.Error);
        },
        onSettled: () => {
          setIsDeletingJob(false);
          queryClient.invalidateQueries(['jobslist']);
        },
      }
    );
    setJobToDelete(0);
    setNameOfJobToDelete('');
  };

  const openDeleteModal = (): void => {
    setDeleteJobModal(true);
  };

  if (isLoading || isDeletingJob) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-sm font-medium text-gray-500">Loading Jobs...</p>
      </div>
    );
  }
  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Jobs</h1>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            {!roles.includes(Roles.Viewer) && (
              <span className="shadow-sm">
                <Link to="/jobs/0/edit" type="button" className="btn btn-primary">
                  Create Job
                </Link>
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between">
        <OpenJobs
          jobs={data.filter((job) => job.status === 'Draft' || job.status === 'Open')}
          openDeleteModal={openDeleteModal}
          setJobToDelete={setJobToDelete}
          setNameOfJobToDelete={setNameOfJobToDelete}
          roles={roles}
        />
        <FinalisedJobs jobs={data.filter((job) => job.status === 'Finalised')} />
        <ArchivedJobs jobs={data.filter((job) => job.status === 'Archive')} />
        <JobModal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} />
        <DeleteJobModal
          isOpen={isDeleteJobModal}
          setOpen={setDeleteJobModal}
          name={nameOfJobToDelete}
          changeStatus={deleteJob}
        />
      </div>
    </>
  );
};

export default JobList;
