import React, { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useApi } from '../contexts/ApiContext';
import { ToastType, useToast } from '../contexts/ToastContext';
import { downloadHandler } from '../Helper';
import Loader from '../icons/Loader.svg?react';

interface Props {
  isOpen: boolean;
  closeModal(open: boolean): void;
  exportType: string;
  jobId: number;
  assetClassIds?: number[];
}

interface ExportOptions {
  jobId: number;
  assetHierarchy: boolean;
  assetAssumptions: boolean;
  insuranceAssumptions: boolean;
  apportionment: boolean;
  componentAssumptions: boolean;
  modellingAssumptions: boolean;
  valuationClasses: boolean;
  financialHierarchy: boolean;
  valuationProfiles: boolean;
  valuationProfileScores: boolean;
  valuationProfileRules: boolean;
}

const FrameworkExportModal: React.FC<Props> = ({ isOpen, closeModal, exportType, jobId, assetClassIds = [] }) => {
  const { addToast } = useToast();
  const api = useApi();
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm<ExportOptions>({
    defaultValues: {
      jobId,
    },
  });

  const onSubmit = (exportOptions: ExportOptions): void => {
    setIsExporting(true);
    api.post('/api/AssetHierarchy/Export', { ...exportOptions, assetClassIds }, { responseType: 'blob' }).then(
      ({ data }) => {
        setIsExporting(false);
        downloadHandler(data, `${exportType} Export Framework.xlsx`);
      },
      (error) => {
        setIsExporting(false);
        addToast(`Framework Export Failed`, ToastType.Error);
      }
    );
  };

  const checkAllFramework = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setIsCheckAll(true);
      setValue('assetHierarchy', true);
      setValue('financialHierarchy', true);
      setValue('assetAssumptions', true);
      setValue('insuranceAssumptions', true);
      setValue('componentAssumptions', true);
      setValue('modellingAssumptions', true);
      setValue('valuationClasses', true);
      setValue('apportionment', true);
      setValue('valuationProfiles', true);
      setValue('valuationProfileScores', true);
      setValue('valuationProfileRules', true);
    } else {
      setIsCheckAll(false);
      setValue('assetHierarchy', false);
      setValue('financialHierarchy', false);
      setValue('assetAssumptions', false);
      setValue('insuranceAssumptions', false);
      setValue('componentAssumptions', false);
      setValue('modellingAssumptions', false);
      setValue('valuationClasses', false);
      setValue('apportionment', false);
      setValue('valuationProfiles', false);
      setValue('valuationProfileScores', false);
      setValue('valuationProfileRules', false);
    }
  };

  const checkAllJobFramework = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setIsCheckAll(true);
      setValue('assetHierarchy', true);
      setValue('assetAssumptions', true);
      setValue('insuranceAssumptions', true);
      setValue('componentAssumptions', true);
      setValue('modellingAssumptions', true);
      setValue('apportionment', true);
    } else {
      setIsCheckAll(false);
      setValue('assetHierarchy', false);
      setValue('assetAssumptions', false);
      setValue('insuranceAssumptions', false);
      setValue('componentAssumptions', false);
      setValue('modellingAssumptions', false);
      setValue('apportionment', false);
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0" open={isOpen} onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-2 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-h-200 sm:max-w-3xl sm:w-full sm:p-6">
              <div className="flex flex-col">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={(): void => closeModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="p-2">
                  <div className="text-center">
                    <h2 className="mt-2 text-lg font-medium text-gray-900">Framework Export Options</h2>
                    <p className="mt-1 text-sm text-gray-500">{exportType}</p>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="mt-6 flex flex-col items-center">
                    <input type="hidden" {...register('jobId', { valueAsNumber: true })} />
                    <fieldset className="col-span-2">
                      <div className="grid grid-cols-2 gap-4 gap-x-16 mb-4">
                        {jobId === 0 ? (
                          <>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  type="checkbox"
                                  onChange={(e): void => checkAllFramework(e)}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="assetHierarchy" className="font-medium text-gray-700">
                                  {isCheckAll ? 'Unselect all' : 'Select all'}
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('assetHierarchy')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="assetHierarchy" className="font-medium text-gray-700">
                                  Asset Hierarchy
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('valuationClasses')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="valuationClasses" className="font-medium text-gray-700">
                                  Valuation Classes
                                </label>
                              </div>
                            </div>

                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('financialHierarchy')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="financialHierarchy" className="font-medium text-gray-700">
                                  Financial Hierarchy
                                </label>
                              </div>
                            </div>

                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('apportionment')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="apportionment" className="font-medium text-gray-700">
                                  Apportionment
                                </label>
                              </div>
                            </div>

                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('assetAssumptions')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="assetAssumptions" className="font-medium text-gray-700">
                                  Asset Assumptions
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('valuationProfiles')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="valuationProfiles" className="font-medium text-gray-700">
                                  Valuation Profiles
                                </label>
                              </div>
                            </div>

                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('insuranceAssumptions')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="insuranceAssumptions" className="font-medium text-gray-700">
                                  Insurance Assumptions
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('valuationProfileScores')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="valuationProfileScores" className="font-medium text-gray-700">
                                  Valuation Profile Scores
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('componentAssumptions')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="componentAssumptions" className="font-medium text-gray-700">
                                  Component Assumptions
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('valuationProfileRules')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="valuationProfileRules" className="font-medium text-gray-700">
                                  Valuation Profile Rules
                                </label>
                              </div>
                            </div>

                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('modellingAssumptions')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="modellingAssumptions" className="font-medium text-gray-700">
                                  Modelling Assumptions
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  type="checkbox"
                                  onChange={(e): void => checkAllJobFramework(e)}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="assetHierarchy" className="font-medium text-gray-700">
                                  {isCheckAll ? 'Unselect all' : 'Select all'}
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('insuranceAssumptions')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="insuranceAssumptions" className="font-medium text-gray-700">
                                  Insurance Assumptions
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('assetHierarchy')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="assetHierarchy" className="font-medium text-gray-700">
                                  Asset Hierarchy
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('assetAssumptions')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="assetAssumptions" className="font-medium text-gray-700">
                                  Asset Assumptions
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('apportionment')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="apportionment" className="font-medium text-gray-700">
                                  Apportionment
                                </label>
                              </div>
                            </div>

                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('componentAssumptions')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="componentAssumptions" className="font-medium text-gray-700">
                                  Component Assumptions
                                </label>
                              </div>
                            </div>
                            <div className="relative flex justify-start" />
                            <div className="relative flex justify-start">
                              <div className="flex h-5 items-center">
                                <input
                                  {...register('modellingAssumptions')}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="modellingAssumptions" className="font-medium text-gray-700">
                                  Modelling Assumptions
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </fieldset>
                    <div className="w-full mt-4 flex justify-end">
                      <button
                        type="submit"
                        disabled={isExporting}
                        className="inline-flex items-center justify-center rounded-md px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                      >
                        {!isExporting ? (
                          <span>Export</span>
                        ) : (
                          <>
                            <Loader className="animate-spin -ml-1 mr-2 h-5 w-5 text-gray-400" />
                            <span>Exporting</span>
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FrameworkExportModal;
