import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Transition } from '@headlessui/react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { yupResolver } from '@hookform/resolvers/yup';

import { ErrorMessage } from './ErrorMessage';
import Loader from '../icons/Loader.svg?react';
import { useStrategy } from '../contexts/StrategyContext';

interface Props {
  isOpen: boolean;
  closeModal(): void;
}

export interface Job {
  id: number;
  name: string;
  valuationDate: Date;
  type: string;
  status: string;
}

const JobModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const CopyJobSchema = yup.object().shape({
    name: yup
      .string()
      .max(100, 'Maximum length is 100.')
      .required('Name is required')
      .matches(/^[a-zA-Z0-9_]+([a-zA-Z0-9_ ]+)*$/, 'Special characters are not allowed'),
    valuationDate: yup.date().required('Valuation Date is required').nullable(),
    type: yup.string().required('Please select a type'),
    status: yup.string().required('Please select a status'),
  });

  const [job, setJob] = useState<Job>({} as Job);

  const { activeStrategy, copyStrategy, isCopyingStrategy } = useStrategy();
  const {
    register,
    handleSubmit,
    control,

    formState: {
      errors,
    },
  } = useForm<Job>({
    reValidateMode: 'onBlur',
    resolver: yupResolver(CopyJobSchema),
  });

  const onSubmit = (data: Job): void => {
    console.log('data', data);
  };

  return (
    <div
      hidden={!isOpen}
      className={`bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center ${isOpen && 'absolute'}`}
    >
      <Transition
        show={isOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-100"
        enterTo="opacity-0"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
      </Transition>

      <Transition
        show={isOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className="bg-white  px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{job?.id ? 'Edit' : 'Create'} Job</h3>
            <div className="mt-6 sm:mt-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex flex-col">
                    <div className="mt-1 relative shadow-sm">
                      <input
                        defaultValue={job.name}
                        id="name"
                        {...register('name')}
                        type="text"
                        className={`block w-full pr-10 sm:text-sm focus:outline-none ${
                          errors?.name
                            ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                            : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                        }`}/>
                    </div>
                    {errors?.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Date of Valuation
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex flex-col">
                    <div className="mt-1 relative shadow-sm">
                      <Controller
                        name="valuationDate"
                        control={control}
                        render={({ field: { onChange, onBlur, value, name, ref }}) => (
                          <ReactDatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            className={`block w-full pr-10 sm:text-sm focus:outline-none ${
                              errors?.valuationDate
                                ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                                : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                            }`}
                            placeholderText="Select date"
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    </div>
                    {errors?.valuationDate && <ErrorMessage>{errors.valuationDate.message}</ErrorMessage>}
                  </div>
                </div>
              </div>

              <div className="pt-6 sm:pt-5">
                <div role="group" aria-labelledby="label-notifications">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                    <div>
                      <div
                        className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                        id="label-notifications"
                      >
                        Type
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="max-w-lg">
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center">
                            <input
                              id="type"
                              {...register('type')}
                              type="radio"
                              value="Comprehensive"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                              Comprehensive
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="type"
                              {...register('type')}
                              type="radio"
                              value="Desktop"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                              Desktop
                            </label>
                          </div>
                          {errors?.type && <ErrorMessage>{errors.type.message}</ErrorMessage>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-6 sm:pt-5">
                <div role="group" aria-labelledby="label-notifications">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                    <div>
                      <div
                        className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                        id="label-notifications"
                      >
                        Status
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="max-w-lg">
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center">
                            <input
                              id="push_everything"
                              {...register('status')}
                              type="radio"
                              value="Finalised"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                              Finalised
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="push_everything"
                              {...register('status')}
                              type="radio"
                              value="Direct Cost"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                              Archived
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="push_email"
                              {...register('status')}
                              type="radio"
                              value="Market"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                            <label htmlFor="push_email" className="ml-3 block text-sm font-medium text-gray-700">
                              Open
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="push_nothing"
                              {...register('status')}
                              type="radio"
                              value="Income"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                            <label htmlFor="push_nothing" className="ml-3 block text-sm font-medium text-gray-700">
                              Draft
                            </label>
                          </div>
                          {errors?.status && <ErrorMessage>{errors.status.message}</ErrorMessage>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span className="flex w-full shadow-sm sm:col-start-2">
                <button
                  type="submit"
                  className="inline-flex justify-center w-full border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  {!isCopyingStrategy ? <span>Submit</span> : <Loader className="animate-spin w-5 h-5 ml-2 -mr-1" />}
                </button>
              </span>
              <span className="mt-3 flex w-full shadow-sm sm:mt-0 sm:col-start-1">
                <button
                  onClick={closeModal}
                  type="button"
                  className="inline-flex justify-center w-full border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </form>
        </div>
      </Transition>
    </div>
  );
};

export default JobModal;
