import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { MaintenancePlan } from '../../models/MaintenancePlan';
import { useApi } from '../../contexts/ApiContext';
import { ToastType, useToast } from '../../contexts/ToastContext';
import ImageModal from '../Asset/ImageModal';
import { currencyFormat } from '../../Format';
import { Drivers, MaintenanceStatuses, Priorities } from '../../models/Enums';
import { useAuth } from '../../contexts/AuthContext';

const MaintenancePlanView: React.FC<{}> = () => {
  const { id = '' } = useParams<{ id: string }>();
  const [imageIndex, setImageIndex] = useState<number>(0);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [maintenancePlan, setMaintenancePlan] = useState<MaintenancePlan>({} as MaintenancePlan);
  const api = useApi();
  const { clientGuid } = useAuth();

  const { addToast } = useToast();

  useEffect(() => {
    if (parseInt(id) > 0) {
      api
        .get<MaintenancePlan>(`/api/MaintenancePlan/Get?id=${parseInt(id)}`)
        .then(({ data }) => {
          setMaintenancePlan(data);
        })
        .catch((error) => {
          addToast('Error getting maintenance plan');
        });
    }
  }, [id]);

  const deleteMaintenancePlan = (): void => {
    api
      .post(`/api/MaintenancePlan/Delete`, { maintenancePlanId: id })
      .then(({ data }) => {
        addToast(`Successfully deleted Maintenance Plan`);
        navigate(-1);
      })
      .catch((error) => {
        addToast(`Unable to remove Maintenance Plan`, ToastType.Error);
      });
  };

  if (Object.keys(maintenancePlan).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="">
      <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="flex justify-between px-4 py-5 sm:px-6">
                <div className="">
                  <div className="flex space-x-4">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                      {maintenancePlan.space} - {maintenancePlan.item}
                    </h2>
                    {maintenancePlan.status === MaintenanceStatuses.Open ? (
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Open
                      </span>
                    ) : (
                      <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                        Closed
                      </span>
                    )}
                  </div>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    <p className="mt-2 text-sm text-gray-500">
                      Created by <span className="font-medium text-gray-900">{maintenancePlan.createdBy}</span>
                    </p>
                    <p className="mt-2 text-sm text-gray-500">
                      <NavLink to={`/assets/${maintenancePlan.assetId}`} className="btn btn-link">
                        {maintenancePlan.assetName}
                      </NavLink>
                    </p>
                  </p>
                </div>
                <div className="mt-5 flex justify-center sm:mt-0 space-x-4">
                  <button
                    type="button"
                    onClick={(): void => {
                      deleteMaintenancePlan();
                    }}
                    className="btn btn-link text-red-600 self-center"
                  >
                    Delete
                  </button>
                  <NavLink to={`/maintenanceplans/${id}/edit`} className="btn btn-primary self-center">
                    Edit
                  </NavLink>
                </div>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Description</dt>
                    <dd className="mt-1 text-sm text-gray-900">{maintenancePlan.description}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Treatment{' '}
                      <span className="font-normal text-xs italic">({maintenancePlan.treatmentType || 'Maintenance'})</span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">{maintenancePlan.treatment}</dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Impact</dt>
                    <dd className="mt-1 text-sm text-gray-900">{Priorities[maintenancePlan.impact]}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Urgency</dt>
                    <dd className="mt-1 text-sm text-gray-900">{Priorities[maintenancePlan.urgency]}</dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Est Timeframe</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {format(new Date(maintenancePlan.estimatedDate), 'LLLL yyyy')}{' '}
                      <span className="text-gray-500 text-xs">({maintenancePlan.estimatedTimeframe} years)</span>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Estimated Cost</dt>
                    <dd className="mt-1 text-sm text-gray-900">{currencyFormat.format(maintenancePlan.estimatedCost)}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Driver</dt>
                    <dd className="mt-1 text-sm text-gray-900">{Drivers[maintenancePlan.driver]}</dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Element</dt>
                    <dd className="mt-1 text-sm text-gray-900">{maintenancePlan.element}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Group</dt>
                    <dd className="mt-1 text-sm text-gray-900">{maintenancePlan.group}</dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Sub Group</dt>
                    <dd className="mt-1 text-sm text-gray-900">{maintenancePlan.subGroup}</dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
        </div>

        <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3 max-h-screen">
          <div className="bg-white px-4 py-2 shadow sm:rounded-lg sm:px-6">
            <div className="mt-6 flow-root overflow-y-auto max-h-80vh">
              <ul className="grid grid-cols-3 lg:grid-cols-1 gap-x-4 gap-y-8 sm:gap-x-6 xl:gap-x-8 ">
                {maintenancePlan.images.map((file, index) => {
                  const [start, end] = file.split('/');
                  const url = `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${start}/thumbnails/${
                    end.split('.')[0]
                  }.jpeg`;
                  return (
                    <li key={index} className="relative">
                      <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                        <img src={url} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                        <button
                          type="button"
                          className="absolute inset-0 focus:outline-none"
                          onClick={(): void => {
                            setImageIndex(index);
                            setIsModalOpen(true);
                          }}
                        >
                          {/* <span className="sr-only">View details for {file.title}</span> */}
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>
      </div>

      {maintenancePlan.images !== undefined && maintenancePlan.images.length > 0 && (
        <ImageModal
          isOpen={isModalOpen}
          closeModal={(): void => setIsModalOpen(false)}
          imageUrls={maintenancePlan.images}
          index={imageIndex}
          images={[]}
        />
      )}
    </div>
  );
};

export default MaintenancePlanView;
