import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStrategy } from '../contexts/StrategyContext';
import { Filter, FilterForm } from '../components/Filter';
import FilterIcon from '../icons/FilterIcon.svg?react';
import Loader from '../icons/Gears.svg?react';
import { ToastType, useToast } from '../contexts/ToastContext';
import LifeCycleTable from '../components/LifecycleTable';
import TotalCostingChart from '../components/TotalCostingChart';
import LOSConditionChart from '../components/LOSConditionChart';
import { FilterTable } from '../components/FilterTable';
import { ReportCover } from '../components/ReportCover';
import { SummaryReport, SummaryReportData } from '../components/SummaryReport';
import { CostAnalysis } from '../components/interfaces';
import { downloadHandler } from '../Helper';
import { useApi } from '../contexts/ApiContext';

interface Props {}

export const DetailAnalysis = (props: Props) => {
  const api = useApi();
  const [filters, setFilters] = useState<FilterForm>({} as FilterForm);
  const { strategyid: strategyId, comparisonstrategyid: comparisonStrategyId } =
    useParams<{ strategyid: string; comparisonstrategyid: string }>();
  const { getStrategy } = useStrategy();
  const { id, name, baselineStrategyName, assetClass } = getStrategy(parseInt(strategyId));
  const { addToast } = useToast();
  const [loading, setLoading] = useState<number>(0);

  const [costAnalysis, setCostAnalysis] = useState<CostAnalysis>({} as CostAnalysis);

  const [summaryReportData, setSummaryReportData] = useState<SummaryReportData>({} as SummaryReportData);
  const [isFilterMenu, setIsFilterMenu] = useState<boolean>(true);
  const clearReport = (): void => {
    setIsFilterMenu(true);
    setCostAnalysis({} as CostAnalysis);
  };

  const exportReport = (strategyId: number, comparisonStrategyId: number): void => {
    api
      .post(
        '/api/Reports/DetailedAnalysisExport',
        { strategyId, comparisonStrategyId, ...filters },
        { responseType: 'blob' }
      )
      .then(
        ({ data }) => {
          downloadHandler(data, `${name}.csv`);
        },
        (error) => {
          addToast('API Fetch Failed', ToastType.Error);
        }
      );
  };

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setLoading(1);
      api
        .post('/api/Reports/DetailedAnalysis', {
          strategyId,
          comparisonStrategyId,
          ...filters,
        })
        .then(({ data }) => {
          setCostAnalysis(data.detail);
          setSummaryReportData(data.summary);
          setLoading(0);
        })
        .catch((error) => {
          addToast('Unable to retrieve report data', ToastType.Error);
          setLoading(0);
        });
    }
  }, [filters]);
  return (
    <>
      <div className="hidden print:block">
        <ReportCover baseStrategy={baselineStrategyName} proposedStrategy={name} />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        {loading > 0 && (
          <div className="mt-16">
            <Loader className="h-64 w-64 transition ease-in-out duration-150" />
          </div>
        )}

        {Object.keys(costAnalysis).length > 0 && (
          <div className="">
            <div className="pagebreak" />
            {Object.keys(filters).length > 0 && (
              <div className="py-4 flex flex-col">
                <FilterTable {...filters} assetClass={assetClass} />
                <span className="inline-flex shadow-sm self-end w-auto sm:mx-6 lg:mx-8 print:hidden">
                  <button
                    onClick={(): void => clearReport()}
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                  >
                    <FilterIcon className="w-4 h-4 text-gray-600" />
                  </button>
                </span>
              </div>
            )}
            <LOSConditionChart data={[costAnalysis.actualCosts, ...costAnalysis.projectedCosts]} />

            <div className="pagebreak" />
            <TotalCostingChart
              data={costAnalysis.projectedCosts}
              comparisonStrategy={baselineStrategyName}
              strategy={name}
            />

            <div className="pagebreak" />
            <SummaryReport {...summaryReportData} />

            <div className="flex justify-between mt-8">
              <h1 className="text-xl font-semibold text-gray-900">Yearly Figures</h1>

              <button
                onClick={(): void => exportReport(parseInt(strategyId), parseInt(comparisonStrategyId))}
                type="button"
                className="print:hidden text-sm underline text-indigo-600 hover:text-indigo-900 transition ease-in-out duration-150 mr-2"
              >
                Export to CSV
              </button>
            </div>

            <LifeCycleTable data={costAnalysis.projectedCosts} />
          </div>
        )}

        <Filter
          strategyId={id}
          assetClass={assetClass}
          setFilters={setFilters}
          isFilterMenu={isFilterMenu}
          setIsFilterMenu={setIsFilterMenu}
        />
      </div>
    </>
  );
};
