import React from 'react';
import Loader from '../icons/Loader.svg?react';

interface Props {}

export const UnauthenticatedContent: React.FC<Props> = () => (
  // TODO: Make this prettier
  (<div className="flex min-h-screen justify-center items-center text-base leading-6 bg-gray-200 space-x-4 text-gray-700 sm:text-xl sm:leading-7">
    <Loader className="animate-spin w-8 h-8" />
  </div>)
);
