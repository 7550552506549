import { Fragment, useCallback, useEffect, useState } from 'react';
import { Menu, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChevronUpIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';
import { Roles } from '../models/Role';
import { useAuth } from '../contexts/AuthContext';
import Loader from '../icons/Loader.svg?react';
import { useApi } from '../contexts/ApiContext';
import { useCopyNode } from '../contexts/CopyNodeContext';
import { HierarchyNode } from '../models/HierarchyNode';

export interface NodeDataSource {
  getChildren(node: HierarchyNode): Promise<HierarchyNode[]>;
}

interface NodeViewProps {
  node: HierarchyNode;
  deleteAction: (node: HierarchyNode) => void;
  trimAction: (node: HierarchyNode) => void;
  copyAction: (node: HierarchyNode) => void;
  pasteAction: (parentId: number) => void;
  editAction: (node: HierarchyNode) => void;
}

export const NodeView: React.FC<NodeViewProps> = ({
  node,
  editAction,
  copyAction,
  deleteAction,
  pasteAction,
  trimAction,
}) => {
  const [open, setOpen] = useState(false);
  const { roles } = useAuth();
  const api = useApi();
  const { setCopyNode, copyNode } = useCopyNode();

  const renderURL = (level: number): string => {
    switch (level) {
      case 0:
        return 'GetAssetClassNodes';
      case 1:
        return 'GetAssetTypeNodes';
      case 2:
        return 'GetAssetSubTypeNodes';
      case 3:
        return 'GetComponentNameNodes';
      case 4:
        return 'GetComponentTypeNodes';
      default:
        return 'GetComponentSubTypeNodes';
    }
  };

  const { data: children = [] as HierarchyNode[], isLoading } = useQuery<HierarchyNode[], Error>(
    ['hierarchy', node.id],
    () =>
      api
        .get<HierarchyNode[]>(`/api/AssetHierarchy/${renderURL(node.level + 1)}?parentId=${node.id}`)
        .then(({ data }) => data),
    { enabled: open }
  );

  const renderHierarchyLevel = (level: number): JSX.Element => {
    switch (level) {
      case 0:
        return (
          <div className="flex-shrink-0 bg-indigo-500 flex items-center justify-center w-16 text-indigo-50 text-sm font-medium rounded-l-md">
            AC
          </div>
        );
      case 1:
        return (
          <div className="flex-shrink-0 bg-indigo-500 flex items-center justify-center w-16 text-indigo-50 text-sm font-medium rounded-l-md">
            AT
          </div>
        );
      case 2:
        return (
          <div className="flex-shrink-0 bg-indigo-500 flex items-center justify-center w-16 text-indigo-50 text-sm font-medium rounded-l-md">
            AST
          </div>
        );
      case 3:
        return (
          <div className="flex-shrink-0 bg-indigo-900 flex items-center justify-center w-16 text-indigo-50 text-sm font-medium rounded-l-md">
            C
          </div>
        );
      case 4:
        return (
          <div className="flex-shrink-0 bg-indigo-900 flex items-center justify-center w-16 text-indigo-50 text-sm font-medium rounded-l-md">
            CT
          </div>
        );
      default:
        return (
          <div className="flex-shrink-0 bg-indigo-900 flex items-center justify-center w-16 text-indigo-50 text-sm font-medium rounded-l-md">
            CST
          </div>
        );
    }
  };

  const copyNodeAction = (): void => {
    setCopyNode(node);
  };

  useEffect(() => {
    if (copyNode.level === 0) {
      pasteAction(node.id);
    }
  }, [copyNode]);

  const hasNodes = node.nodeCount > 0;
  const hasAssetsOrComponents = (node.assetCount && node.assetCount > 0) || (node.componentCount && node.componentCount > 0);
  return (
    <>
      <li
        key={node.id}
        className={`ml-${node.level * 16} flex shadow-sm rounded-md bg-white w-max z-0`}
        // as="li"
        // appear
        // show
        // enter="transition duration-500 ease-out"
        // enterFrom="transform scale-95 opacity-0"
        // enterTo="transform scale-100 opacity-100"
        // leave="transition duration-75 ease-out"
        // leaveFrom="transform scale-100 opacity-100"
        // leaveTo="transform scale-95 opacity-0"
      >
        {renderHierarchyLevel(node.level)}
        {node.assetAssumptionsId != undefined && (
          <NavLink
            className="flex-1 px-4 py-2 text-sm truncate btn btn-link"
            to={`/assetassumptions/${node.assetAssumptionsId}`}
          >
            {node.name}
          </NavLink>
        )}

        {node.componentAssumptionsId != undefined && (
          <NavLink
            className="flex-1 px-4 py-2 text-sm truncate btn btn-link"
            to={`/componentassumptions/${node.componentAssumptionsId}`}
          >
            {node.name}
          </NavLink>
        )}
        {node.componentAssumptionsId == undefined && node.assetAssumptionsId == undefined && (
          <div className="flex-1 px-4 py-2 text-sm truncate">
            <div className="text-gray-900 font-medium hover:text-gray-600">{node.name}</div>
          </div>
        )}
        {node.assetCount != undefined && node.assetCount > 0 && (
          <span className="inline-flex mr-2 h-auto self-center items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
            {node.assetCount}
          </span>
        )}
        {node.componentCount != undefined && node.componentCount > 0 && (
          <span className="inline-flex mr-2 h-auto self-center items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
            {node.componentCount}
          </span>
        )}
        {hasNodes && (
          <ChevronUpIcon
            onClick={(e): void => {
              hasNodes && setOpen((o) => !o);
              e.stopPropagation();
            }}
            className={`${
              open ? 'transform rotate-180' : ''
            } m-2 w-5 self-center h-5 ease-in-out duration-150 cursor-pointer`}
          />
        )}
        {open && isLoading && <Loader className="self-center animate-spin w-4 h-4 m-2" />}

        {!roles.includes(Roles.Viewer) && (
          <Menu as="div" className="relative inline-block text-left self-center mx-2">
            <div>
              <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <MenuItems className="z-10 mx-3 origin-top absolute left-5 -top-4 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    {(): JSX.Element => (
                      <button
                        onClick={(): void => editAction(node)}
                        className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                      >
                        Edit
                      </button>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {(): JSX.Element => (
                      <button
                        onClick={(): void => copyNodeAction()}
                        className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                      >
                        Copy
                      </button>
                    )}
                  </MenuItem>
                  {!hasAssetsOrComponents && (
                    <MenuItem>
                      {(): JSX.Element => (
                        <button
                          onClick={(): void => deleteAction(node)}
                          className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                        >
                          Delete
                        </button>
                      )}
                    </MenuItem>
                  )}
                  {hasAssetsOrComponents && (
                    <MenuItem>
                      {(): JSX.Element => (
                        <button
                          onClick={(): void => trimAction(node)}
                          className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                        >
                          Trim
                        </button>
                      )}
                    </MenuItem>
                  )}
                  {copyNode != undefined && copyNode.level - 1 === node.level && (
                    <MenuItem>
                      {(): JSX.Element => (
                        <button
                          onClick={(): void => pasteAction(node.id)}
                          className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                        >
                          Paste
                        </button>
                      )}
                    </MenuItem>
                  )}
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        )}
      </li>
      {open &&
        children.length > 0 &&
        !isLoading &&
        children.map((child) => (
          <NodeView
            node={child}
            copyAction={copyAction}
            deleteAction={deleteAction}
            editAction={editAction}
            pasteAction={pasteAction}
            trimAction={trimAction}
          />
        ))}
    </>
  );
};
