import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { Asset } from '../../models/Asset';
import { AssetReplacementCost } from '../../models/ReplacementCostSummary';
import { useApi } from '../../contexts/ApiContext';
import { useToast } from '../../contexts/ToastContext';
import { useAssetReplacementCostById, useUpdateAssetReplacementCost } from '../../hooks/UseReplacementCost';
import { useAssetById } from '../../hooks/UseAsset';
import { ErrorMessage } from '../../components/ErrorMessage';
import Loader from '../../icons/Loader.svg?react';

export const AssetReplacementCostEdit: React.FC = () => {
  const api = useApi();
  const { addToast } = useToast();
  const { id, assetId } = useParams<{ id: string; assetId: string }>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [adjustmentPct, setAdjustmentPct] = useState<number>(0);
  const [area, setArea] = useState<number>(0);
  const history = useNavigate();
  const { refetch, data: assetReplacementCost = {} as AssetReplacementCost } = useAssetReplacementCostById(parseInt(id));
  const { refetch: refetchAsset, data: asset = {} as Asset } = useAssetById(parseInt(assetId));
  const updateAssetReplacementCost = useUpdateAssetReplacementCost();
  const areaOptions: string[] = ['sqm', 'lm', 'cum', 'km', 'no.'];

  const AssetReplacementCostSchema = yup.object().shape({
    name: yup.string().max(100, 'Maximum length is 100.').required('Item Type is required'),
    length: yup.number().positive().label('Length').min(1).required('Please enter a valid length'),
    width: yup.number().positive().label('Width').min(1).required('Please enter a valid width'),
    quantity: yup.number().positive().label('Quantity').min(1).required('Please enter a valid quantity'),
    localityFactorPct: yup.number().positive().label('Locality Factor').min(0),
    specifiedRate: yup
      .number()
      .positive()
      .label('Specified Rate')
      .nullable()
      .transform((_, val) => (val === Number(val) ? val : null)),
  });

  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm<AssetReplacementCost>({
    defaultValues: { id: parseInt(id), area: 0, length: 0, width: 0 },
    reValidateMode: 'onBlur',
    resolver: yupResolver(AssetReplacementCostSchema),
  });
  const adjustedRateWatch: number = watch('adjustedRate');
  const widthWatch: number = watch('width');
  const lengthWatch: number = watch('length');
  useEffect(() => {
    if (Number.isNaN(adjustedRateWatch) || adjustedRateWatch === 0) {
      setAdjustmentPct(0);
    } else if (adjustedRateWatch != undefined && Number(id) !== 0) {
      setAdjustmentPct((adjustedRateWatch / assetReplacementCost.unitRate - 1) * 100);
    } else if (asset.assumptions != undefined) {
      setAdjustmentPct((adjustedRateWatch / asset.assumptions.unitRate - 1) * 100);
    }
  }, [adjustedRateWatch]);

  useEffect(() => {
    if (Object.keys(assetReplacementCost).length > 0 && Number(id) !== 0) {
      reset({
        ...assetReplacementCost,
        localityFactorPct: assetReplacementCost.localityFactorPct * 100,
        adjustmentPct: assetReplacementCost.adjustmentPct * 100,
      });
      setAdjustmentPct(assetReplacementCost.adjustmentPct * 100);
    }
  }, [assetReplacementCost]);

  useEffect(() => {
    setArea(widthWatch * lengthWatch);
  }, [widthWatch, lengthWatch]);

  useEffect(() => {
    if (Object.keys(asset).length > 0 && Number(id) === 0) {
      reset({
        unitRate: asset.assumptions?.unitRate,
        localityFactorPct: asset.assumptions?.localityFactorPct != undefined ? asset.assumptions.localityFactorPct * 100 : 0,
        adjustedRate: asset.assumptions?.unitRate,
      });
    }
  }, [asset]);
  const onSubmit = handleSubmit((formAssetReplacementCost) => {
    setIsSaving(true);
    updateAssetReplacementCost.mutate(
      {
        ...formAssetReplacementCost,
        unitRate:
          formAssetReplacementCost.id === 0 && asset.assumptions !== undefined
            ? asset.assumptions?.unitRate
            : assetReplacementCost.unitRate,
        assetId: formAssetReplacementCost.id === 0 ? Number(assetId) : formAssetReplacementCost.assetId,
        localityFactorPct: formAssetReplacementCost.localityFactorPct / 100,
        adjustmentPct: adjustmentPct / 100,
      },
      {
        onSuccess: (data, variables, context) => {
          if (assetId != undefined) {
            history(`/assets/${assetId}/replacementcosts`);
          } else history(`/assetreplacementcosts/${id}`);
        },
        onSettled: () => {
          setIsSaving(false);
        },
      }
    );

  });

  return (
    <form onSubmit={onSubmit} className="mx-4 space-y-6">
      <div className="bg-white shadow mt-4 px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">General</h3>
            {/* <p className="mt-1 text-sm text-gray-500">
              Use a permanent address where you can receive mail.
            </p> */}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <input type="hidden" {...register('id', { valueAsNumber: true })} />
            <input type="hidden" {...register('assetId', { valueAsNumber: true })} />

            <div className="bg-white space-y-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="areaType" className="block text-sm font-medium text-gray-700">
                  Area Type
                </label>
                <div className="flex space-x-2 items-baseline">
                  <div className="lg:w-1/3">
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...register('areaType')}
                        className="block w-full w-full rounded-md border-gray-300 bg-none text-left shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:px-1 sm:text-sm lg:px-2"
                      >
                        {areaOptions.map((areaType) => (
                          <option key={areaType} value={areaType}>
                            {areaType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <div className="flex space-x-2 items-baseline">
                  <div className="lg:w-1/3">
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        {...register('name')}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      />
                      {errors?.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow mt-4 px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Dimensions</h3>
            {/* <p className="mt-1 text-sm text-gray-500">
              Use a permanent address where you can receive mail.
            </p> */}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="bg-white space-y-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Length
                </label>
                <div className="flex space-x-2 items-baseline">
                  <div>
                    <div className="mt-1 relative rounded-md shadow-sm lg:w-24">
                      <input
                        type="number"
                        step="any"
                        {...register('length', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      />
                      {errors?.length && <ErrorMessage>{errors.length.message}</ErrorMessage>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Width
                </label>
                <div className="flex space-x-2 items-baseline">
                  <div>
                    <div className="mt-1 relative rounded-md shadow-sm lg:w-24">
                      <input
                        type="number"
                        step="any"
                        {...register('width', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      />
                      {errors?.width && <ErrorMessage>{errors.width.message}</ErrorMessage>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Area
                </label>
                <div className="flex space-x-2 items-baseline">
                  <div>
                    <div className="mt-1 relative rounded-md shadow-sm lg:w-24">
                      <input
                        type="number"
                        disabled
                        {...register('area', { valueAsNumber: true })}
                        value={area}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block bg-gray-100 w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      />
                      {errors?.area && <ErrorMessage>{errors.area.message}</ErrorMessage>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Quantity
                </label>
                <div className="flex space-x-2 items-baseline">
                  <div>
                    <div className="mt-1 relative rounded-md shadow-sm lg:w-40">
                      <input
                        type="number"
                        step="any"
                        {...register('quantity', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      />
                      {errors?.quantity && <ErrorMessage>{errors.quantity.message}</ErrorMessage>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow mt-4 px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Values</h3>
            {/* <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p> */}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="bg-white space-y-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Unit Rate
                </label>
                <div className="flex space-x-2 items-baseline lg:w-1/4">
                  <div className="flex-grow">
                    <div className="mt-1 relative rounded-md shadow-sm ">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="text"
                        disabled
                        {...register('unitRate', { valueAsNumber: true })}
                        value={getValues('unitRate')}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full bg-gray-100 pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                        aria-describedby="price-currency"
                      />
                    </div>
                  </div>
                  {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800">
                    $120
                  </span> */}
                </div>
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Adjusted Rate
                </label>
                <div className="flex space-x-2 items-baseline lg:w-1/4">
                  <div className="flex-grow">
                    <div className="mt-1 relative rounded-md shadow-sm ">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="number"
                        step="any"
                        {...register('adjustedRate', { valueAsNumber: true })}
                        defaultValue={
                          Object.keys(assetReplacementCost).length > 0
                            ? ((assetReplacementCost.adjustmentPct + 1) * assetReplacementCost.unitRate).toFixed(2)
                            : 0
                        }
                        id="price"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                        aria-describedby="price-currency"
                      />
                    </div>
                    {adjustedRateWatch < 0 && <ErrorMessage>Adjusted Rate cannot be less than 0</ErrorMessage>}
                  </div>
                  {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800">
                    $120
                  </span> */}
                </div>
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Locality Factor
                </label>
                <div className="flex space-x-3 items-baseline">
                  <div>
                    <div className="mt-1 relative rounded-md shadow-sm lg:w-30">
                      <input
                        type="number"
                        step="any"
                        {...register('localityFactorPct', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        {/* Heroicon name: solid/question-mark-circle */}
                        <span className="text-gray-500 sm:text-sm">%</span>
                      </div>
                    </div>
                    {errors?.localityFactorPct && <ErrorMessage>{errors.localityFactorPct.message}</ErrorMessage>}
                  </div>
                  {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800">
                    5%
                  </span> */}
                </div>
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Adjustment Pct
                </label>
                <div className="flex space-x-3 items-baseline">
                  <div>
                    <div className="mt-1 relative rounded-md shadow-sm lg:w-30">
                      <input
                        disabled
                        type="number"
                        {...register('adjustmentPct', { valueAsNumber: true })}
                        value={adjustmentPct.toFixed(6)}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block bg-gray-100 w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        {/* Heroicon name: solid/question-mark-circle */}
                        <span className="text-gray-500 sm:text-sm">%</span>
                      </div>
                    </div>
                  </div>
                  {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800">
                    2%
                  </span> */}
                </div>
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                  Specified Rate
                </label>
                <div className="flex space-x-2 items-baseline lg:w-1/4">
                  <div className="flex-grow">
                    <div className="mt-1 relative rounded-md shadow-sm ">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="number"
                        step="any"
                        {...register('specifiedRate', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                        aria-describedby="price-currency"
                      />
                    </div>
                    {errors?.specifiedRate && <ErrorMessage>{errors.specifiedRate.message}</ErrorMessage>}
                  </div>
                  {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-light bg-indigo-100 text-indigo-800">
                    $120
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              history(-1);
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button disabled={isSaving} type="submit" className="btn btn-primary">
                {isSaving ? (
                  <>
                    <span>Saving</span>
                    <Loader className="animate-spin w-5 h-5 mx-2" />
                  </>
                ) : (
                  <span>Save</span>
                )}
              </button>
        </div>
      </div>
    </form>
  );
};
