import React, { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import axios, { Canceler } from 'axios';
import { format } from 'date-fns';
import { CalendarIcon, UserIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Exclaimation from '../icons/Exclaimation.svg?react';
import Loader from '../icons/Loader.svg?react';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useApi } from '../contexts/ApiContext';
import { useAssetSearch } from '../contexts/AssetSearchContext';
import { ImportResult } from '../models/ImportResults';
import { ImportResultList } from '../pages/ImportLog/ImportJobLogView';
import { useAuth } from '../contexts/AuthContext';
import { ImportLogDetail } from '../models/ImportLogItem';

interface ImportFile {
  file: FileWithPath;
  logType: string;
  isValid: boolean | null;
  closeAction(): void;
  url: string;
  parameters?: { [key: string]: string };
  options?: JSX.Element;
  invalidQueries?: string[];
  setIsSuccess?(): void;
  redirectUrl?: string;
}

const { CancelToken } = axios;
let cancel: Canceler;

const FileInput: React.FC<ImportFile> = ({
  file,
  isValid,
  logType,
  closeAction,
  url,
  parameters = {},
  options,
  invalidQueries,
  setIsSuccess,
}) => {
  const api = useApi();
  const [isImporting, setIsImporting] = useState(false);
  const [importResults, setImportResults] = useState<ImportResult>({} as ImportResult);
  const { setAssets } = useAssetSearch();
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const history = useNavigate();
  const { fullName } = useAuth();

  type UploadResult = ImportLogDetail | { message: string };

  const uploadFile = (): void => {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(parameters).forEach((key) => formData.append(key, parameters[key]));
    setIsImporting(true);
    api
      .post<UploadResult>(url, formData, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
      .then(
        ({ data }) => {
          setIsImporting(false);
          setIsSuccess && setIsSuccess();
          queryClient.invalidateQueries(['asset']);
          queryClient.invalidateQueries(['component']);
          setAssets([]);
          if (data == undefined) {
            addToast('Unknown Error', ToastType.Warn);
          } else if ('result' in data) {
            queryClient.invalidateQueries(['job']);
            setImportResults(data.result);
            invalidQueries?.forEach((element) => {
              queryClient.invalidateQueries([element]);
            });
            if (data.id > 0 && logType !== 'Lease') {
              history(`/import${logType}log/${data.id}`);
            }
          } else if ('message' in data) {
            addToast(data.message);
            closeAction();
          }
        },
        (error) => {
          setIsImporting(false);
          if (axios.isCancel(error)) {
            addToast('Request Cancelled', ToastType.Warn);
          } else {
            addToast('Framework Import Failed. Please contact your system administrator.', ToastType.Error);
          }

          closeAction();
        }
      );
  };

  function handleClick(): void {
    if (cancel !== undefined) {
      setIsImporting(false);
      cancel();
    }
  }

  useEffect(() => {
    console.log('importResults changed', importResults);
  }, [importResults]);

  if (Object.keys(importResults).length > 0) {
    return (
      <div className="flow-root">
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow m-4">
          <div className="px-4 py-5 sm:px-6">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  Import Log
                </h2>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <UserIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {fullName}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {format(new Date(), "PP hh:mm aaaaa'm'")}
                  </div>
                </div>
              </div>
              <div className="mt-5 flex lg:ml-4 lg:mt-0">
                <span className="hidden sm:block">
                  <button
                    type="button"
                    onClick={(): void => history(-1)}
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Back
                  </button>
                </span>
              </div>
            </div>
          </div>
          <ImportResultList {...importResults} />
        </div>
        <div className="mt-8 flex justify-end">
          <button
            type="button"
            onClick={closeAction}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Back to Import
          </button>
        </div>
      </div>
    );
  }
  if (isValid) {
    return (
      <>
        <div className="flex space-x-2 items-center justify-between">
          <span className="text-sm font-medium">{file.name}</span>
          <div className="flex flex space-x-2">
            <button
              type="button"
              onClick={isImporting ? handleClick : closeAction}
              className="inline-flex items-center px-4 py-2 focus:outline-none text-sm leading-6 font-medium rounded-md text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
            >
              Cancel
            </button>
            {isImporting ? (
              <button
                disabled
                type="button"
                className="btn border-indigo-600 leading-6 text-indigo-800 bg-white hover:text-indigo-700 focus:border-indigo-300 transition ease-in-out duration-150"
              >
                Uploading
                <Loader className="animate-spin w-5 h-5 ml-2 -mr-1" />
              </button>
            ) : (
              <span className="relative inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={(): void => uploadFile()}
                  className="btn border-indigo-600 leading-6 text-indigo-800 bg-white hover:text-indigo-700 focus:border-indigo-300 transition ease-in-out duration-150"
                >
                  Upload
                </button>
                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-600 opacity-75" />
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-indigo-700" />
                </span>
              </span>
            )}
          </div>
        </div>
        {options}
      </>
    );
  }
  return (
    <button
      onClick={closeAction}
      type="button"
      className="inline-flex items-center justify-center px-4 py-2 border rounded-md border-transparent font-medium text-red-700 bg-red-100 hover:bg-red-50 focus:outline-none focus:border-red-300 focus:shadow-outline-red active:bg-red-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
    >
      Error with {file.name}
      <span>
        <Exclaimation className="animate-bounce ml-2 h-5 w-5 text-red-700" />
      </span>
    </button>
  );
};

export default FileInput;
