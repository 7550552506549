import React from 'react';
import Loader from '../icons/Loader.svg?react';



export const CustomCallback: React.FC<{}> = () => {
  // TODO: Make this page Prettier
  return (
<div className="flex flex-col justify-center items-center h-screen">
  <Loader className="animate-spin w-6 h-6 mb-3"/>
  <p className="text-sm font-medium text-gray-500">Loading AVP</p>
</div>
  );
};
