import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChevronUpIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { formatDistance } from 'date-fns';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ColumnDef } from '@tanstack/react-table';
import ExportFrameworkModal from '../components/ExportFrameworkModal';
import FileInput from '../components/FileInput';
import { useApi } from '../contexts/ApiContext';
import { useAuth } from '../contexts/AuthContext';
import { ToastType, useToast } from '../contexts/ToastContext';
import Loader from '../icons/Loader.svg?react';
import { HierarchyType } from '../models/HierarchyType';
import { ImportLogItem } from '../models/ImportLogItem';
import { BasicTable } from '../components/BasicTable';
import { removeTimezoneOffset } from '../Helper';
import { Roles } from '../models/Role';

interface ImportProps {
  isAssetHierarchyChanges: boolean;
  setIsAssetHierarchyChanges(isAssetHierarchyChanges: boolean): void;
  isFinancialHierarchyChanges: boolean;
  setIsFinancialHierarchyChanges(isFinancialHierarchyChanges: boolean): void;
  isValuationClassChanges: boolean;
  setIsValuationClassChanges(isValuationClassChanges: boolean): void;
  isAssumptionsChanges: boolean;
  setIsAssumptionsChanges(isAssumptionsChanges: boolean): void;
  isValuationProfileChanges: boolean;
  setIsValuationProfileChanges(isValuationProfileChanges: boolean): void;
}

const ImportOptions: React.FC<ImportProps> = ({
  isAssetHierarchyChanges,
  setIsAssetHierarchyChanges,
  isFinancialHierarchyChanges,
  setIsFinancialHierarchyChanges,
  isValuationClassChanges,
  setIsValuationClassChanges,
  isAssumptionsChanges,
  setIsAssumptionsChanges,
  isValuationProfileChanges,
  setIsValuationProfileChanges,
}) => (
  <div className="">
    <div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Worksheets to Process</h3>
    </div>
    <div className="">
      <fieldset>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                checked={isAssetHierarchyChanges}
                onChange={(): void => setIsAssetHierarchyChanges(!isAssetHierarchyChanges)}
                name="isAssetHierarchyChanges"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                Asset Hierarchy
              </label>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                checked={isFinancialHierarchyChanges}
                onChange={(): void => setIsFinancialHierarchyChanges(!isFinancialHierarchyChanges)}
                name="isFinancialHierarchyChanges"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                Financial Hierarchy
              </label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                checked={isValuationClassChanges}
                onChange={(): void => setIsValuationClassChanges(!isValuationClassChanges)}
                name="isValuationClassChanges"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                Valuation Class
              </label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                checked={isAssumptionsChanges}
                onChange={(): void => setIsAssumptionsChanges(!isAssumptionsChanges)}
                name="isAssumptionsChanges"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                Assumptions
              </label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div className="mt-4 space-y-4">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                checked={isValuationProfileChanges}
                onChange={(): void => setIsValuationProfileChanges(!isValuationProfileChanges)}
                name="isValuationProfileChanges"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                Valuation Profile
              </label>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
);

export const FrameworkImport: React.FC<{}> = () => {
  const api = useApi();
  const ref = useRef<HTMLInputElement[]>([]);
  const { client, roles } = useAuth();
  const { addToast } = useToast();
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [file, setFile] = useState<FileWithPath>({} as FileWithPath);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [assetClassIds, setAssetClassIds] = useState<number[]>([]);
  const [isAssetHierarchyChanges, setIsAssetHierarchyChanges] = useState<boolean>(true);
  const [isFinancialHierarchyChanges, setIsFinancialHierarchyChanges] = useState<boolean>(true);
  const [isValuationClassChanges, setIsValuationClassChanges] = useState<boolean>(true);
  const [isAssumptionsChanges, setIsAssumptionsChanges] = useState<boolean>(true);
  const [assetClassList, setAssetClassList] = useState<HierarchyType[]>([]);
  const [importLogs, setImportLogList] = useState<ImportLogItem[]>([]);
  const [isValuationProfileChanges, setIsValuationProfileChanges] = useState<boolean>(true);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const onDrop = useCallback((acceptedFiles) => {
    setIsValid(true);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/vnd.ms-excel': ['.xlsx'], 'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'] },
  });
  useEffect(() => {
    api.get('/api/Lookup/AssetClass').then(
      ({ data }) => {
        setAssetClassList(data);
      },
      (error) => {
        addToast(`Failed to get Asset Classes`, ToastType.Error);
      }
    );

    api.get('/api/ImportLog/ListFrameworkLogs').then(
      ({ data }) => {
        setImportLogList(data);
      },
      (error) => {
        addToast(`Failed to get Asset Classes`, ToastType.Error);
      }
    );
  }, []);

  const columns = React.useMemo<ColumnDef<ImportLogItem>[]>(
    () => [
      {
        header: 'File Name',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
        },
        accessorKey: 'fileName',
      },
      {
        header: 'Result',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
        },
        accessorKey: 'isSuccess',
        cell: ({ row }): JSX.Element => {
          if (row.original.isSuccess) {
            return (
              <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20">
                Success
              </span>
            );
          }
          return (
            <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
              Error
            </span>
          );
        },
      },
      {
        header: 'User',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
        },
        accessorKey: 'createdBy',
      },

      {
        header: 'Created',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
        },
        accessorKey: 'created',
        cell: ({ row }): string =>
          formatDistance(removeTimezoneOffset(new Date(row.original.created)), new Date(), { addSuffix: true }),
      },
      {
        header: '',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        accessorKey: 'id',
        cell: ({ row }): JSX.Element => (
          <Link to={`/importframeworklog/${row.original.id}`} className="text-indigo-600 hover:text-indigo-900 font-medium">
            View
          </Link>
        ),
      },
    ],
    []
  );

  const handleCheck = (event) => {
    let updatedList = [...assetClassIds];
    if (event.target.checked) {
      updatedList = [...assetClassIds, Number(event.target.value)];
    } else {
      updatedList.splice(assetClassIds.indexOf(Number(event.target.value)), 1);
    }
    setAssetClassIds(updatedList);
  };

  const checkAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setIsCheckAll(true);
      for (let i = 0; i < ref.current.length; i++) {
        ref.current[i].checked = true;
      }
      setAssetClassIds(assetClassList.map((ac) => ac.value));
    } else {
      setIsCheckAll(false);
      for (let i = 0; i < ref.current.length; i++) {
        ref.current[i].checked = false;
      }
      setAssetClassIds([]);
    }
  };

  return (
    <div className="align-middle inline-block min-w-full border-b border-gray-200">
      <form className="space-y-8 divide-y divide-gray-200 relative p-5 mx-3 my-5 bg-white shadow-md rounded-md">
        <div className="space-y-8">
          <div>
            <div className="flex justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Import</h3>
                <p className="mt-1 text-sm text-gray-500">Import a spreadsheet containing framework data</p>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                {Object.entries(file).length !== 0 ? (
                  <FileInput
                    file={file}
                    isValid={isValid}
                    closeAction={(): void => setFile({} as FileWithPath)}
                    url="/api/AssetHierarchy/Import"
                    logType="framework"
                    invalidQueries={['valuationProfileRuleList', 'hierarchy', 'valuationProfilesList']}
                    parameters={{
                      isAssetHierarchyChanges: isAssetHierarchyChanges.toString(),
                      isFinancialHierarchyChanges: isFinancialHierarchyChanges.toString(),
                      isValuationClassChanges: isValuationClassChanges.toString(),
                      isAssumptionsChanges: isAssumptionsChanges.toString(),
                      isValuationProfileChanges: isValuationProfileChanges.toString(),
                    }}
                    options={
                      <ImportOptions
                        isAssetHierarchyChanges={isAssetHierarchyChanges}
                        setIsAssetHierarchyChanges={setIsAssetHierarchyChanges}
                        isFinancialHierarchyChanges={isFinancialHierarchyChanges}
                        setIsFinancialHierarchyChanges={setIsFinancialHierarchyChanges}
                        isValuationClassChanges={isValuationClassChanges}
                        setIsValuationClassChanges={setIsValuationClassChanges}
                        isAssumptionsChanges={isAssumptionsChanges}
                        setIsAssumptionsChanges={setIsAssumptionsChanges}
                        isValuationProfileChanges={isValuationProfileChanges}
                        setIsValuationProfileChanges={setIsValuationProfileChanges}
                      />
                    }
                  />
                ) : (
                  <div
                    {...getRootProps()}
                    className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                  >
                    <div className="space-y-1 text-center">
                      <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" />
                      <div className="flex text-sm text-gray-600">
                        <div className="btn btn-link">
                          <span>Upload a file</span>
                          <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                        </div>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-gray-500">XLSX up to 100MB</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="space-y-8 relative p-5 mx-3 my-5 bg-white shadow-md rounded-md">
        <div className="space-y-8">
          <div>
            <div className="flex justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Export</h3>
                <p className="mt-1 text-sm text-gray-500">Export {client} framework</p>
              </div>
              <button
                onClick={(): void => setOpen(true)}
                type="button"
                className="inline-flex items-center justify-center rounded-md px-4 py-2 border border-gray-300 text-sm font-medium shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {!isExporting ? (
                  <span>Export</span>
                ) : (
                  <>
                    <Loader className="animate-spin -ml-1 mr-2 h-5 w-5 text-gray-400" />
                    <span>Exporting</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="pb-2 mb-2 border-b border-gray-200">
            <input
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              type="checkbox"
              onChange={(e): void => checkAll(e)}
            />
            <label className="ml-3 text-sm font-medium text-gray-700">{isCheckAll ? 'Unselect all' : 'Select all'}</label>
          </div>
          {assetClassList.map((ac, index) => (
            <div key={index}>
              <input
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                value={ac.value}
                type="checkbox"
                ref={(e): void => {
                  if (e) {
                    ref.current[index] = e;
                  }
                }}
                onChange={handleCheck}
              />
              <label className="ml-3 text-sm font-medium text-gray-700">{ac.label}</label>
            </div>
          ))}
        </div>
      </div>
      {roles.includes(Roles.Valuer) && (
        <Disclosure>
          {({ open }): JSX.Element => (
            <div className="py-4 relative p-5">
              <Disclosure.Button className="flex space-x-6 text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                <div className="text-lg leading-6 font-medium text-gray-900">Import History</div>
                <ChevronUpIcon className={`${open ? 'transform rotate-180' : ''} w-5 h-5 ease-in-out duration-150`} />
              </Disclosure.Button>
              <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                <BasicTable data={importLogs} columns={columns} tablePageSize={5} />
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
      )}
      <ExportFrameworkModal
        isOpen={isOpen}
        closeModal={setOpen}
        exportType={client}
        jobId={0}
        assetClassIds={assetClassIds}
      />
    </div>
  );
};
