import React, { useState, useCallback } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import axios, { Canceler } from 'axios';
import Exclaimation from '../icons/Exclaimation.svg?react';
import Loader from '../icons/Loader.svg?react';
import { ToastType, useToast } from '../contexts/ToastContext';
import { useApi } from '../contexts/ApiContext';

interface ImportDocument {
  files: FileWithPath[];
  isValid: boolean | null;
  closeAction(): void;
  url: string;
  parameters?: { [key: string]: string };
  options?: JSX.Element;
  setIsSuccess?(): void;
  reloadAction?(): void;
}

let cancel: Canceler;

const DocumentInput: React.FC<ImportDocument> = ({
  files,
  isValid,
  closeAction,
  url,
  parameters = {},
  options,
  reloadAction,
}) => {
  const api = useApi();
  const [isImporting, setIsImporting] = useState(false);
  const { addToast } = useToast();
  const uploadFile = (): void => {
    try {
      setIsImporting(true);
      for (let i = 0; i < files.length; i += 1) {
        const formData = new FormData();
        formData.append('file', files[i]);
        Object.keys(parameters).forEach((key) => formData.append(key, parameters[key]));
        api.post(url, formData, {}).then(
          ({ data }) => {
            if (reloadAction) {
              setTimeout(() => {
                reloadAction();
              }, 2050);
            }
          },
          (error) => {
            setIsImporting(false);
            if (axios.isCancel(error)) {
              addToast('Request Cancelled', ToastType.Warn);
            } else {
              addToast('API Request Failed', ToastType.Error);
            }
            closeAction();
          }
        );
      }
      setIsImporting(false);
      addToast(`${files.length} files have been uploaded.`);
      closeAction();
    } catch (error) {
      console.log(error);
    }
  };

  function handleClick(): void {
    if (cancel !== undefined) {
      setIsImporting(false);
      cancel();
    }
  }
  if (isValid) {
    return (
      <>
        <div className="flex space-x-2 items-center justify-between">
          <div className="flex space-x-2 items-center justify-between">
            <span className="text-sm font-medium">Upload {files.length} file(s) to document container</span>
          </div>
          <div className="flex flex space-x-2 py-2">
            {isImporting ? (
              <button
                disabled
                type="button"
                className="btn border-indigo-600 leading-6 text-indigo-800 bg-white hover:text-indigo-700 focus:border-indigo-300 transition ease-in-out duration-150"
              >
                Uploading
                <Loader className="animate-spin w-5 h-5 ml-2 -mr-1" />
              </button>
            ) : (
              <span className="relative inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={(): void => uploadFile()}
                  className="btn border-indigo-600 leading-6 text-indigo-800 bg-white hover:text-indigo-700 focus:border-indigo-300 transition ease-in-out duration-150"
                >
                  Upload
                </button>
                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-600 opacity-75" />
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-indigo-700" />
                </span>
              </span>
            )}
            <button
              type="button"
              onClick={isImporting ? handleClick : closeAction}
              className="inline-flex items-center px-4 py-2 focus:outline-none text-sm leading-6 font-medium rounded-md text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
            >
              Cancel
            </button>
          </div>
        </div>
        {options}
      </>
    );
  }
  return (
    <button
      onClick={closeAction}
      type="button"
      className="inline-flex items-center justify-center px-4 py-2 border rounded-md border-transparent font-medium text-red-700 bg-red-100 hover:bg-red-50 focus:outline-none focus:border-red-300 focus:shadow-outline-red active:bg-red-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
    >
      Error with upload
      <span>
        <Exclaimation className="animate-bounce ml-2 h-5 w-5 text-red-700" />
      </span>
    </button>
  );
};

export default DocumentInput;
