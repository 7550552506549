import React, { Fragment, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Menu, MenuButton, Transition, MenuItem, MenuItems } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { Link, NavLink, useParams } from 'react-router-dom';
import {
  defaults,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '../components/BasicTable';
import { ValuationProfile, ValuationProfileScore } from '../models/ValuationProfile';
import { percentFormat } from '../Format';
import { useApi } from '../contexts/ApiContext';
import Loader from '../icons/Loader.svg?react';
import { Roles } from '../models/Role';
import { useAuth } from '../contexts/AuthContext';

defaults.font.family = 'proxima-nova';

export const ValuationProfileView: React.FC<{}> = () => {
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const { roles } = useAuth();
  const { isLoading, data: valuationProfile = {} as ValuationProfile } = useQuery<ValuationProfile, Error>(
    ['valuationProfile', id],
    () => api.post('/api/ValuationProfile/Get', { id }).then((res) => res.data)
  );
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const columns = React.useMemo<ColumnDef<ValuationProfileScore>[]>(
    () => [
      {
        header: 'Consumption Score',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        accessorKey: 'consumptionScoreStart',
        cell: ({ row }): string => `${row.original.consumptionScoreStart} - ${row.original.consumptionScoreEnd}`,
      },
      {
        header: 'RUL',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        accessorKey: 'rulPctStart',
        cell: ({ row }): string =>
          `${percentFormat.format(row.original.rulPctStart)} - ${percentFormat.format(row.original.rulPctEnd)}`,
      },
      {
        header: 'RSP',
        meta: {
          headerClassName: 'px-3.5 py-3',
          className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        },
        accessorKey: 'rspPctStart',
        cell: ({ row }): string =>
          `${percentFormat.format(row.original.rspPctStart)} - ${percentFormat.format(row.original.rspPctEnd)}`,
      },
    ],
    []
  );
  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-base font-medium text-gray-500">Loading Profile</p>
      </div>
    );
  }
  const { valuationProfileScores, name, description } = valuationProfile;

  const rspData =
    valuationProfileScores.length > 0
      ? [
          ...valuationProfileScores.map((score) => ({
            label: score.consumptionScoreStart,
            x: score.rulPctStart * 100,
            y: score.rspPctStart * 100,
          })),
          {
            label: valuationProfileScores[valuationProfileScores.length - 1].consumptionScoreEnd,
            x: valuationProfileScores[valuationProfileScores.length - 1].rulPctEnd * 100,
            y: valuationProfileScores[valuationProfileScores.length - 1].rspPctEnd * 100,
          },
        ]
      : [];
  const data = {
    datasets: [
      {
        label: `${name}`,
        data: rspData,
        fill: false,
        backgroundColor: 'rgba(44, 82, 130, 0.9)',
        borderColor: 'rgba(44, 82, 130, 1)',
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title(context) {
            return `Consumption Score`;
          },
          label(context) {
            return ` ${context.dataset.data[context.dataIndex].label}`;
          },
        },
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        min: 0,
        max: 100,
        ticks: {
          callback(value): string {
            return `${value}%`;
          },
          beginAtZero: true,
        },
        title: {
          display: true,
          text: 'RSP',
        },
      },
      x: {
        type: 'linear' as const,
        min: 0,
        max: 100,
        reverse: true,
        ticks: {
          callback(value): string {
            return `${value}%`;
          },
          beginAtZero: true,
        },
        title: {
          display: true,
          text: 'RUL',
        },
      },
    },
  };

  return (
    <div className="">
      <div className="mt-4 px-4 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-white overflow-hidden shadow">
          <div className="lg:flex lg:items-center lg:justify-between p-6">
            <div className="flex-1 min-w-0 space-y-4">
              <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">{name}</h2>
              <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                <p className="text-sm font-medium text-gray-500">{description}</p>
              </div>
            </div>
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
              <span className="hidden sm:block ml-3" />

              {!roles.includes(Roles.Viewer) && (
                <span className="sm:ml-3">
                  <Link to={`/valuationprofiles/${id}/edit`} type="button" className="order-2 btn btn-primary">
                    Edit
                  </Link>
                </span>
              )}

              {/* Dropdown */}
              <Menu as="span" className="ml-3 relative sm:hidden">
                <MenuButton className="btn border-gray-300 shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  More
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                </MenuButton>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem>
                      {(): JSX.Element => (
                        <NavLink
                          to="#"
                          className={({ isActive }) => `block px-4 py-2 text-sm text-gray-700 ${isActive && 'bg-gray-100'}`}
                        >
                          Edit
                        </NavLink>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {(): JSX.Element => (
                        <NavLink
                          to="#"
                          className={({ isActive }) => `block px-4 py-2 text-sm text-gray-700 ${isActive && 'bg-gray-100'}`}
                        >
                          View
                        </NavLink>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>{' '}
      </div>
      <div className="mx-4 mt-2 flex">
        <div className="bg-white shadow rounded-md overflow-hidden sm:rounded-md my-2 p-4 w-full">
          <Line data={data} plugins={[]} options={options} />
        </div>
        <div className="w-1/2 ml-4">
          <BasicTable columns={columns} data={valuationProfileScores} tablePageSize={10} />
        </div>
      </div>
    </div>
  );
};
