import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Asset } from '../models/Asset';

interface AssetInforContainerProps {
  asset: Asset;
  valuerMode: boolean;
}

const AssetInfoContainer: React.FC<AssetInforContainerProps> = ({ asset, valuerMode }) => {
  const { name, id } = asset;
  const ellipsis = name && name?.length > 28 ? '...' : '';
  const summary = `${name ? name.substring(0, 28) : ''}${ellipsis}`;
  const navigateLink = valuerMode ? `/assets/${asset?.id}/quickedit` : `/assets/${asset?.id || 0}`;
  return (
    <>
      <div className="px-3 py-2 bg-white border-b border-gray-200 flex flex-row justify-between">
        <h3 className="font-semibold text-gray-900 dark:text-white">{summary}</h3>
        <NavLink to={navigateLink}>
          <ArrowTopRightOnSquareIcon className="h-4 w-4 text-indigo-600 hover:text-indigo-900" />
        </NavLink>
      </div>
      {/* <div className="flex flex-row py-1.5 px-2">
        {dbasset?.images[0]?.thumbnail ? (
          <img
            className="inline-block h-28 w-36 min-h-full rounded-md"
            src={dbasset?.images[0]?.thumbnail}
            alt={asset?.name}
          />
        ) : (
          <ImageNotFound className="h-28 w-36 min-h-full rounded-md text-grey-600" />
        )}
        <div className="px-3 py-2">
          <p>{dbasset?.reference}</p>
          <p>Gross: {currencyFormat.format(dbasset?.valuationMethod?.gross || 0)}</p>
          <p>CV: {currencyFormat.format(dbasset?.valuationMethod?.currentValue || 0)}</p>
        </div>
      </div> */}
    </>
  );
};

export default AssetInfoContainer;
