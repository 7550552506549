import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import Select from 'react-select';
import { useParams, useNavigate, unstable_usePrompt } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Asset } from '../../models/Asset';
import { useAssetById, useUpdateAsset } from '../../hooks/UseAsset';
import { HierarchyType } from '../../models/HierarchyType';
import { useApi } from '../../contexts/ApiContext';
import Loader from '../../icons/Loader.svg?react';

export const Accounting: React.FC = () => {
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const history = useNavigate();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { data: asset = {} as Asset } = useAssetById(parseInt(id));
  const {
    register,
    handleSubmit,
    watch,
    reset,

    formState: { isDirty, isSubmitted, errors },
  } = useForm<Asset>({
    defaultValues: { ...asset },
  });
  const financialAssetClassIdWatch = watch('financialAssetClassId');

  const {
    data: financialAssetClasses = [
      { value: asset.financialAssetClassId, label: asset.financialAssetClass },
    ] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(['FinancialAssetClassList'], () =>
    api.post('/api/Lookup/FinancialAssetClass', {}).then(({ data }) => data)
  );

  const { data: valuationClasses = [{ value: asset.valuationClassId, label: '' }] as HierarchyType[] } = useQuery<
    HierarchyType[],
    Error
  >(['ValuationClassList'], () => api.post('/api/ValuationClass/List', {}).then(({ data }) => data));

  const fetchFinancialAssetSubClass = async (financialAssetClassId: number): Promise<HierarchyType[]> =>
    api.get(`/api/Lookup/FinancialAssetSubClass?financialAssetClassId=${financialAssetClassId}`).then(({ data }) => data);

  const {
    refetch: refetchFinancialAssetSubClasss,
    data: financialAssetSubClasses = [
      { value: asset.financialAssetSubClassId, label: asset.financialAssetSubClass },
    ] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(
    ['Financial Sub Class List', financialAssetClassIdWatch],
    async () => fetchFinancialAssetSubClass(financialAssetClassIdWatch),
    { enabled: false }
  );

  const updateAsset = useUpdateAsset();

  const onSubmit = handleSubmit((formAsset) => {
    setIsSaving(true);
    updateAsset.mutate({ ...asset, ...formAsset }, { onSettled: () => setIsSaving(false) });
  });

  useEffect(() => {
    reset({
      ...asset,
    });
    console.log('asset', asset);
  }, [asset]);

  useEffect(() => {
    if (financialAssetClassIdWatch > 0) {
      refetchFinancialAssetSubClasss();
    }
  }, [financialAssetClassIdWatch]);

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form className="space-y-6" onSubmit={onSubmit}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Financial Classification</h3>
          </div>
          <div className="md:mt-0 md:col-span-2">
            <div className="bg-white space-y-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">Financial Class</label>
                  <select
                    {...register('financialAssetClassId', { valueAsNumber: true })}
                    className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    {financialAssetClasses.map((financialAssetClass) => (
                      <option key={financialAssetClass.value} value={financialAssetClass.value}>
                        {financialAssetClass.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">Financial Sub-Class</label>
                  <select
                    {...register('financialAssetSubClassId', { valueAsNumber: true })}
                    className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    {financialAssetSubClasses.map((financialAssetSubClass) => (
                      <option key={financialAssetSubClass.value} value={financialAssetSubClass.value}>
                        {financialAssetSubClass.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">Valuation Class</label>
                  <select
                    {...register('valuationClassId', { valueAsNumber: true })}
                    className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    {valuationClasses.map((valuationClass) => (
                      <option key={valuationClass.value} value={valuationClass.value}>
                        {valuationClass.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3 relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="offers"
                    {...register('isHighestAndBestUse')}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded border-gray-300"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="offers" className="font-medium text-gray-700">
                    Highest and Best Use
                  </label>
                </div>
              </div>
              <div className="flex items-start">
                <div className="h-5 flex items-center">
                  <input
                    {...register('isControlledForFinancialPurpose')}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="isControlledForFinancialPurpose" className="font-medium text-gray-700">
                    Controlled
                  </label>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    {...register('isHeritageAsset')}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="isHeritageAsset" className="font-medium text-gray-700">
                    Heritage Asset
                  </label>
                </div>
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    {...register('isInvestment')}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="isInvestment" className="font-medium text-gray-700">
                    Investment
                  </label>
                </div>
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    {...register('isHeldForSale')}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="isHeldForSale" className="font-medium text-gray-700">
                    Held for Sale
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              if (!isSubmitted && isDirty) {
                if (window.confirm('You have unsaved changes, are you sure you want to leave?')) {
                  return parseInt(id) === 0 ? history('/') : history(-1);
                }
              } else {
                return parseInt(id) === 0 ? history('/') : history(-1);
              }
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button disabled={isSaving} type="submit" className="btn btn-primary">
            {isSaving ? (
              <>
                <span>Saving</span>
                <Loader className="animate-spin w-5 h-5 mx-2" />
              </>
            ) : (
              <span>Save</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};
