import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import * as yup from 'yup';
// eslint-disable-next-line camelcase
import { useParams, unstable_usePrompt, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Asset } from '../../models/Asset';
import { useAssetById, useUpdateAsset } from '../../hooks/UseAsset';
import { useApi } from '../../contexts/ApiContext';
import { ErrorMessage } from '../ErrorMessage';
import Loader from '../../icons/Loader.svg?react';

export const LocationForm: React.FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { data: asset = {} as Asset } = useAssetById(parseInt(id));
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const assetSchema = yup.object().shape({
    reference: yup.string().max(30, 'Maximum length is 30.').required('Asset ID is required'),
    assetClassId: yup.number().required('Asset Class required').typeError('Asset Class required'),
    latitude: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .min(-90, 'Minimum is -90.')
      .max(90, 'Maximum is 90.')
      .nullable(),
    longitude: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .min(-180, 'Minimum is -180.')
      .max(180, 'Maximum is 180.')
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitted, errors },
  } = useForm<Asset>({
    defaultValues: { ...asset },
    resolver: yupResolver(assetSchema),
  });

  // const history = useNavigate();
  const navigate = useNavigate();

  const updateAsset = useUpdateAsset();

  useEffect(() => {
    reset({
      ...asset,
    });
  }, [asset]);

  const onSubmit = handleSubmit((formAsset) => {
    setIsSaving(true);
    updateAsset.mutate({ ...asset, ...formAsset }, { onSettled: () => setIsSaving(false) });
  });

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Address</h3>
              {/* <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p> */}
            </div>
            <div className="md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Address Line
                    </label>
                    <input
                      type="text"
                      {...register('streetAddress')}
                      id="name"
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Suburb
                    </label>
                    <input
                      type="text"
                      {...register('suburb')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="customerReference" className="block text-sm font-medium text-gray-700">
                      City / Town
                    </label>
                    <input
                      type="text"
                      {...register('city')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Post Code
                    </label>
                    <input
                      type="text"
                      {...register('postCode')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="customerReference" className="block text-sm font-medium text-gray-700">
                      State
                    </label>
                    <input
                      type="text"
                      {...register('state')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">GPS</h3>
              {/* <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p> */}
            </div>
            <div className="md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Latitude
                    </label>
                    <input
                      type="text"
                      {...register('latitude', { valueAsNumber: true })}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors?.latitude && <ErrorMessage>{errors.latitude.message}</ErrorMessage>}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="customerReference" className="block text-sm font-medium text-gray-700">
                      Longitude
                    </label>
                    <input
                      type="text"
                      {...register('longitude', { valueAsNumber: true })}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors?.longitude && <ErrorMessage>{errors.longitude.message}</ErrorMessage>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-2">
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={(): void => {
                parseInt(id) === 0 ? navigate('/') : navigate(-1);
              }}
              className="btn btn-secondary"
            >
              Cancel
            </button>
            <button disabled={isSaving} type="submit" className="btn btn-primary">
            {isSaving ? (
              <>
                <span>Saving</span>
                <Loader className="animate-spin w-5 h-5 mx-2" />
              </>
            ) : (
              <span>Save</span>
            )}
          </button>
          </div>
        </div>
      </div>
    </form>
  );
};
