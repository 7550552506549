import React from 'react';
import ArrowNarrowDown from '../icons/ArrowNarrowDown.svg?react';
import ArrowNarrowUp from '../icons/ArrowNarrowUp.svg?react';
import { ProjectedCosts } from './interfaces';

interface Props {
  data?: ProjectedCosts[];
  summary?: boolean;
  url?: string;
  parameters?: object;
}

const LifeCycleTable: React.FC<Props> = ({ data, summary }) => (
  <div className="flex flex-col">
    <div className="my-2 py-2">
      <div className="align-middle min-w-full shadow overflow-hidden sm:border-b border-gray-200">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                {!summary && <>Year</>}
              </th>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Base Maintenance
              </th>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Base Renewal
              </th>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Total
              </th>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Projected Maintenance
              </th>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Projected Renewal
              </th>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Total
              </th>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Increase (Decrease) Projected
              </th>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Base LOS Score
              </th>
              <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Projected LOS Score
              </th>
            </tr>
          </thead>
          <tbody className="odd:bg-white">{data && data.map((item) => <LifecycleTableRow {...item} />)}</tbody>
        </table>
      </div>
    </div>
  </div>
);

const LifecycleTableRow: React.FC<ProjectedCosts> = ({
  name,
  baseLOSScore,
  baseMaintenance,
  baseRenewal,
  baseLOSModelRULPct,
  proposedLOSScore,
  proposedMaintenance,
  proposedRenewal,
  proposedLOSModelRULPct,
  totalBaseCost,
  totalProposedCost,
  totalDifference,
  totalDifferenceAsVariance,
}) => {
  const font = totalDifferenceAsVariance > 0 ? 'text-red-800' : 'text-green-800';
  const background = totalDifferenceAsVariance > 0 ? 'bg-red-100' : 'bg-green-100';
  const arrow = totalDifferenceAsVariance > 0 ? <ArrowNarrowUp className="w-3" /> : <ArrowNarrowDown className="w-3" />;
  return (
    <tr className="even:bg-gray-50 odd:bg-white">
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 font-medium text-gray-900">{name}</td>
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 text-gray-500 text-right">
        {baseMaintenance.toLocaleString()}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 text-gray-500 text-right">
        {baseRenewal.toLocaleString()}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 text-gray-500 text-right">
        {totalBaseCost.toLocaleString()}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 text-gray-500 text-right">
        {proposedMaintenance.toLocaleString()}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 text-gray-500 text-right">
        {proposedRenewal.toLocaleString()}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 text-gray-500 text-right">
        {totalProposedCost.toLocaleString()}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 text-gray-500 text-right">
        {totalDifference.toLocaleString()}
        <span
          className={`inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 ${font} ${background}`}
        >
          {arrow}
          {Math.abs(totalDifferenceAsVariance)}%
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 text-gray-500 text-right">
        {baseLOSScore.toLocaleString()}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-xs leading-5 text-gray-500 text-right">
        {proposedLOSScore.toLocaleString()}
      </td>
    </tr>
  );
};

export default LifeCycleTable;
