import { ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { ReplacementCostType, useAssetByComponentId, useAssetByContentsId } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';
import Loader from '../../icons/Loader.svg?react';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import { useClient } from '../../contexts/ClientContext';
import { Content } from '../../models/Content';

const ContentView: React.FC<{}> = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { refetch, data: asset = {} as Asset } = useAssetByContentsId(parseInt(id));
  const { roles } = useAuth();
  const { checkAssetClassEditable } = useClient();
  const [contents, setContents] = useState<Content>({} as Content);

  useEffect(() => {
    if (Object.keys(asset).length > 0) {
      const content = asset.contents?.find((component) => component.id === parseInt(id));
      setContents(content || ({} as Content));
    }
  }, [asset]);
  const { assetId, name, type, quantity, rate, images } = contents;
  console.log('images', images);
  if (Object.keys(contents).length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-sm font-medium text-gray-500">Loading Contents</p>
      </div>
    );
  }

  return (
    <div className="py-4">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-50 shadow rounded-md overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Contents</h3>
              {!roles.includes(Roles.Viewer) && (
                <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                  <span className="shadow-sm">
                    <Link to={`/contents/${id}/edit`} className="btn btn-primary">
                      Edit
                    </Link>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Item</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{name}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{type}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Asset</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Link
                    to={`/assets/${assetId}`}
                    className="inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                  >
                    {asset.name}
                  </Link>
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Quantity</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{quantity}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Applied Rate</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{rate}</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Total Cost</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{quantity * rate}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="px-4 pt-4 sm:px-6 lg:px-8">
        <div className="bg-white  shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6 bg-gray-50">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Images</h3>
          </div>
          <div className="border-t border-gray-200 bg-white ">
            <ul className="bg-white grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 m-4 max-h-112 overflow-y-auto px-4">
              {images &&
                images.map((image, index) => (
                  <li className="relative">
                    <img src={image.original} alt="" className="object-cover pointer-events-none rounded-md group-hover:opacity-75" />
                    {/* <button
                        onClick={() => {
                          setImage(image);
                          setIsImageViewerModalOpen(true);
                        }}
                        type="button"
                      >
                        <img
                          src={image.original}
                          alt=""
                          className="pointer-events-none object-cover group-hover:opacity-75"
                        />
                      </button>
                      <button
                        type="button"
                        className="absolute p-0.5 w-6 h-6 m-1 bg-red-100 rounded-full"
                        onClick={(): void => {
                          setImageToDelete(image);
                          setIsImageDeletModalOpen(true);
                        }}
                      >
                        <XMarkIcon className="text-red-700 " />
                      </button> */}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentView;
