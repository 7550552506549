import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate, unstable_usePrompt } from 'react-router-dom';
import { useToast } from '../../contexts/ToastContext';
import { useAssetById, useUpdateAsset } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';
import { useApi } from '../../contexts/ApiContext';
import Loader from '../../icons/Loader.svg?react';
import { InsuranceAssumptions } from '../../models/InsuranceAssumptions';

const InsuranceForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: asset = {} as Asset } = useAssetById(parseInt(id));
  const updateAsset = useUpdateAsset();
  const history = useNavigate();
  const api = useApi();
  const { addToast } = useToast();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,

    formState: { isSubmitted, isDirty, errors },
  } = useForm<Asset>({
    defaultValues: { ...asset, insurance: { coverage: 'None' } },
  });
  const watchCoverage = watch('insurance.coverage');

  const onSubmit = handleSubmit((formAsset) => {
    setIsSaving(true);
    updateAsset.mutate(
      {
        ...asset,
        ...formAsset,
        insurance: {
          ...asset.insurance,
          ...formAsset.insurance,
          leadBuildMonths: formAsset.insurance.coverage === 'None' ? 0 : formAsset.insurance.leadBuildMonths,
          demoMonths: formAsset.insurance.coverage === 'None' ? 0 : formAsset.insurance.demoMonths,
          debrisRemovalMinimum: formAsset.insurance.coverage === 'None' ? 0 : formAsset.insurance.debrisRemovalMinimum,
          escalationFactorPct: formAsset.insurance.coverage === 'None' ? 0 : formAsset.insurance.escalationFactorPct / 100,
          debrisRemovalPct: formAsset.insurance.coverage === 'None' ? 0 : formAsset.insurance.debrisRemovalPct / 100,
          professionalFeesPct: formAsset.insurance.coverage === 'None' ? 0 : formAsset.insurance.professionalFeesPct / 100,
        },
      },
      { onSettled: () => setIsSaving(false) }
    );
  });

  useEffect(() => {
    if (Object.keys(asset).length > 0) {
      reset({
        ...asset,
        insurance:
          asset.insurance === null
            ? { coverage: 'None' }
            : {
                ...asset.insurance,
                escalationFactorPct: asset.insurance.escalationFactorPct * 100,
                debrisRemovalPct: asset.insurance.debrisRemovalPct * 100,
                professionalFeesPct: asset.insurance.professionalFeesPct * 100,
              },
      });
    }
  }, [asset]);

  useEffect(() => {
    if (watchCoverage === 'Full' || watchCoverage === 'Indemnity') {
      api
        .get<InsuranceAssumptions>(`api/Assumptions/GetInsuranceAssumptionsBySubType?assetSubTypeId=${asset.assetSubTypeId}`)
        .then(({ data }) => {
          reset({
            ...asset,
            insurance: {
              ...data,
              coverage: watchCoverage,
              leadBuildMonths: data.leadBuildMonths,
              demoMonths: data.demoMonths,
              escalationFactorPct: data.escalationFactorPct * 100,
              professionalFeesPct: data.professionalFeesPct * 100,
              debrisRemovalMinimum: data.debrisRemovalMinimum,
              debrisRemovalPct: data.debrisRemovalPct * 100,
            },
          });
        });
    }
  }, [watchCoverage]);

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form className="space-y-8" onSubmit={onSubmit}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <input type="hidden" {...register('id', { valueAsNumber: true })} />
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Coverage</h3>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Type
                </label>
                <select
                  defaultValue="None"
                  {...register('insurance.coverage')}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md sm:col-span-2 max-w-xs"
                >
                  <option>None</option>
                  <option>Full</option>
                  <option>Indemnity</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {watchCoverage !== 'None' && (
        <>
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Coverage</h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                      Lead Build Months
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                      <input
                        type="number"
                        {...register('insurance.leadBuildMonths', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        step="any"
                        defaultValue={0}
                        aria-describedby="price-currency"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">
                          months
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6">
                    <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                      Demolition Months
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                      <input
                        type="number"
                        {...register('insurance.demoMonths', { valueAsNumber: true })}
                        step="any"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        defaultValue={0}
                        aria-describedby="price-currency"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">
                          months
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Fees</h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                      Escalation Factor
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                      <input
                        type="number"
                        step="any"
                        {...register('insurance.escalationFactorPct', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        defaultValue={0}
                        aria-describedby="price-currency"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">
                          %
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6">
                    <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                      Professional Fees
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                      <input
                        type="number"
                        {...register('insurance.professionalFeesPct', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        step="any"
                        defaultValue={0}
                        aria-describedby="price-currency"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Debris Removal</h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                      Fee
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                      <input
                        type="number"
                        {...register('insurance.debrisRemovalPct', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        step="any"
                        defaultValue={0}
                        aria-describedby="price-currency"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                      Minimum Cost
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm sm:col-span-2 max-w-xs">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="number"
                        step="any"
                        {...register('insurance.debrisRemovalMinimum', { valueAsNumber: true })}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                        defaultValue={0.0}
                        aria-describedby="price-currency"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="pb-2">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={(): void => {
              if (!isSubmitted && isDirty) {
                if (window.confirm('You have unsaved changes, are you sure you want to leave?')) {
                  return parseInt(id) === 0 ? history('/') : history(-1);
                }
              } else {
                return parseInt(id) === 0 ? history('/') : history(-1);
              }
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button disabled={isSaving} type="submit" className="btn btn-primary">
            {isSaving ? (
              <>
                <span>Saving</span>
                <Loader className="animate-spin w-5 h-5 mx-2" />
              </>
            ) : (
              <span>Save</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default InsuranceForm;
