import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from '../../contexts/ApiContext';
import { BasicTable, EnhancedColumn } from '../../components/BasicTable';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ValuationClass } from '../../models/ValuationClass';
import { useAuth } from '../../contexts/AuthContext';
import { Roles } from '../../models/Role';
import Loader from '../../icons/Loader.svg?react';
import { ColumnDef } from '@tanstack/react-table';

const ValuationClassList: React.FC<{}> = () => {
  const api = useApi();
  const { roles } = useAuth();

  const [valuationClasses, setValuationClasses] = useState<ValuationClass[]>([] as ValuationClass[]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const { addToast } = useToast();

  useEffect(() => {
    console.log('valuationClasses', valuationClasses);
  }, [valuationClasses]);

  useEffect(() => {
    api
      .get('/api/ValuationClass/ListSummary')
      .then(({ data }) => {
        console.log('data', data);
        setValuationClasses(data);
      })
      .catch((error) => {
        addToast(`Unable to get valuation classes`, ToastType.Error);
      });
  }, []);

  const removeValuationClass = (valuationClassId: number): void => {
    api
      .post(`/api/ValuationClass/Delete`, { id: valuationClassId })
      .then(({ data }) => {
        setValuationClasses(data);
        addToast(`Successfully deleted valuation class`);
      })
      .catch((error) => {
        addToast(`Unable to delete valuation class`, ToastType.Error);
      });
  };

  const columns = useMemo<ColumnDef<ValuationClass>[]>(
    () => [
      {
        header: 'Name ',
        accessorKey: 'name',
        meta: {
          className: 'px-2 py-3 text-sm leading-5 font-medium sm:truncate',
        },
      },
      {
        header: 'Technique',
        accessorKey: 'valuationTechnique',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Assets',
        accessorKey: 'assetsCount',
        meta: {
          className: 'px-2 py-3 text-sm font-medium sm:truncate leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <span
            className="
                text-sm rounded-full"
          >
            {row.original.assetsCount}
          </span>
        ),
      },
      {
        header: 'Assumptions',
        accessorKey: 'assetAssumptionsCount',
        meta: {
          className: 'px-2 py-3 text-sm font-medium sm:truncate leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <span
            className="
                text-sm rounded-full"
          >
            {row.original.assetAssumptionsCount}
          </span>
        ),
      },
      {
        header: 'Level',
        accessorKey: 'valuationLevel',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: '',
        accessorKey: 'edit',
        meta: {
          className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <div>
            {!roles.includes(Roles.Viewer) && (
              <Link className="btn-link" to={`/valuationclasses/${row.original.id}/edit`}>
                edit
              </Link>
            )}
          </div>
        ),
      },
      {
        header: '',
        accessorKey: 'id',
        meta: {
          className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
        },
        cell: ({ row }): JSX.Element => (
          <div>
            {!roles.includes(Roles.Viewer) && row.original.assetsCount === 0 && row.original.assetAssumptionsCount === 0 && (
              <button
                className="inline-flex items-center rounded border border-transparent bg-red-100 px-2.5 py-1.5 text-xs font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={(): void => removeValuationClass(row.original.id)}
              >
                Delete
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 md:flex md:items-center md:justify-between min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Valuation Classes</h1>
          {!roles.includes(Roles.Viewer) && (
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <span className="shadow-sm">
                <Link to="/ValuationClasses/0/edit" className="btn btn-primary">
                  Create Valuation Class
                </Link>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="mx-4 pt-4">
        {valuationClasses.length > 0 ? (
          <BasicTable columns={columns} data={valuationClasses} />
        ) : (
          <div className="flex flex-col justify-center items-center h-screen">
            <Loader className="animate-spin w-6 h-6 mb-3" />
            <p className="text-sm font-medium text-gray-500">Loading</p>
          </div>
        )}
        {/* <JobModal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} /> */}
      </div>
    </>
  );
};

export default ValuationClassList;
