import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';
import { ToastType, useToast } from '../../contexts/ToastContext';
import Loader from '../../icons/Loader.svg?react';
import { BasicTable } from '../../components/BasicTable';
import { Lease, LeaseDto } from '../../models/Lease';
import { useApi } from '../../contexts/ApiContext';
import { useAuth } from '../../contexts/AuthContext';
import { currencyFormat } from '../../Format';
import { useLeaseSearch } from '../../contexts/LeaseSearchContext';

export const LeaseList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const api = useApi();
  const { roles, client } = useAuth();
  const {
    leases,
    searchText,
    setSearchText,
    leaseType,
    setLeaseType,
    isSearchingLease,
    setIsSearchingLease,
    currentPage,
    setCurrentPage,
    deleteLeases,
    isDeletingLeases,
    exportLeases,
    isExportingLeases,
    tablePageSize,
    setTablePageSize,
  } = useLeaseSearch();

  console.log('leases', leases)
  const columns = React.useMemo<ColumnDef<Lease>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        mata: {
          headerClassName: 'px-2 py-2.5 text-sm leading-5 sm:truncate',
          className: 'px-2 py-2.5 text-sm leading-5 sm:truncate',
        },
        cell: ({ row }): React.ReactElement => (
          <Link to={`/leases/${row.original.id}`} className="text-indigo-600 hover:text-indigo-900">
            {row.original.name}
          </Link>
        ),
      },
      {
        header: 'Asset',
        accessorKey: 'assetId',
        meta: {
          className: 'px-1 py-3 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): React.ReactElement => (
          <Link to={`/assets/${row.original.assetId}`} className="text-indigo-600 hover:text-indigo-900">
            {row.original.assetReference}
          </Link>
        ),
      },
      {
        header: 'Address',
        accessorKey: 'addressLine1',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): React.ReactElement => (
          <>
            <div className="whitespace-nowrap text-xs leading-5">{row.original.addressLine1}</div>
            <div className="flex italic text-xs font-light">{row.original.suburb}</div>
          </>
        ),
      },
      {
        header: 'Lease Type',
        accessorKey: 'leaseType',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
      },
      {
        header: 'Tenant',
        accessorKey: 'tenants',
        meta: {
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): React.ReactElement => (
          <div>{row.original.tenants?.length > 0 ? row.original.tenants[0].name : ''}</div>
        ),
      },
      {
        header: 'Rent',
        accessorKey: 'annualRent',
        meta: {
          headerClassName: 'text-right whitespace-normal',
          className: 'px-2 py-3 text-sm leading-5 sm:truncate text-right',
        },
        cell: ({ row }): React.ReactElement => (
          <>
            <p className="">{`${currencyFormat.format(row.original.annualRent)} p/a`}</p>
            <p className="">{`${currencyFormat.format(row.original.annualRent / 12)} p/m`}</p>
          </>
        ),
      },
      {
        header: 'Lease Term',
        accessorKey: 'leaseExpiryDate',
        meta: {
          headerClassName: 'text-right whitespace-normal',
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): React.ReactElement => (
          <div>{`${
            row.original.leaseStartDate !== null && row.original.leaseStartDate !== undefined
              ? new Date(row.original.leaseStartDate).toLocaleDateString()
              : 'N/A'
          } - ${
            row.original.leaseExpiryDate !== null && row.original.leaseExpiryDate !== undefined
              ? new Date(row.original.leaseExpiryDate).toLocaleDateString()
              : 'N/A'
          }`}</div>
        ),
      },
      {
        header: '',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-right whitespace-normal',
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5 text-right',
        },
        cell: ({ row }): React.ReactElement => (
          <Link to={`/leases/${row.original.id}/edit`} className="text-indigo-600 hover:text-indigo-900 ">
            edit
          </Link>
        ),
      },
    ],
    []
  );

  if (isSearchingLease) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-base font-medium text-gray-500">Loading Leases</p>
      </div>
    );
  }

  return (
    <div className="sm:block">
      <div className="align-middle inline-block min-w-full">
        <div className="relative p-5 m-3 bg-white shadow-md rounded-md">
          <div className="flex-1 min-w-0 mb-3 justify-between flex">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Leases</h1>
            <Link
              to="/leaseimport"
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            >
              Import Leases
            </Link>
          </div>
          <div className="flex items-end flex-wrap space-x-2">
            <div className="flex-grow rounded-md shadow-sm">
              <div className="relative flex items-stretch focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon className="mr-3 h-4 w-4 text-gray-600" aria-hidden="true" />
                </div>
                <input
                  defaultValue={searchText}
                  type="text"
                  name="searchText"
                  onChange={(e): void => setSearchText(e.target.value)}
                  className="py-2 bg-gray-100 focus:ring-indigo-500 text-gray-500 focus:border-indigo-500 rounded-md block w-full pl-9 sm:text-sm border-none placeholder-gray-500 h-9"
                  placeholder="Search Asset ID or Lease Name"
                />
              </div>
            </div>
            <div className="">
              <label htmlFor="valuationPolicy" className="whitespace-nowrap block text-sm font-medium text-gray-700">
                Lease Type
              </label>
              <select
                defaultValue={leaseType}
                name="leaseType"
                onChange={(e): void => setLeaseType(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-none rounded-md bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="All">All</option>
                <option value="Retail">Retail</option>
                <option value="Residential">Residential</option>
                <option value="Commercial">Commercial</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-3 h-50">
        <BasicTable
          columns={columns}
          data={leases}
          isLoading={isSearchingLease}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          exportAction={exportLeases}
          isExporting={isExportingLeases}
          deleteAction={deleteLeases}
          isDeleting={isDeletingLeases}
          tablePageSize={tablePageSize}
          setTablePageSize={setTablePageSize}
          context="Lease"
        />
      </div>
    </div>
  );
};
